import FE from 'froala-editor';
import { StaticLocalization } from '../localizedStrings';

let localizedStrings = new StaticLocalization().strings;

export const initCustomTreatyTemplate = () => {
  FE.PLUGINS.treatyTemplate = (editor) => {
    function insertTemplate() {
      const template = `
      <h1>Treaty Title</h1>

<style>
	table,
	thead,
	tbody,
	tr,
	th,
	td {
		border: none !important; background-color: transparent !important;
	}
	
	table {
		width: 70% !important;/* Ensures the max width */
		table-layout: fixed;/* Ensures fixed table layout */
		overflow: hidden; display: table !important;/* Ensures fixed table layout minimum width */
	}
	
	td {
		border: none;/* Optional: adds a border */
	}
	
	td:first-child {
		width: auto !important;/* Ensures that all content is visible */
		max-width: 30% !important;/* Ensures that the coloumn doesnt take up to much space */
		vertical-align: top;/* Ensures consistent vertical alignment */
		white-space: nowrap !important;/* Prevent word wrapping */
		overflow: hidden !important;/* Hide overflow if text is too long */
	}
	
	td:last-child {
		width: 70% !important;/* Ensures the width */
		overflow-wrap: break-word !important;/* Ensures long text breaks correctly */
		word-break: break-word !important;/* Ensure word-break functionality */
		vertical-align: top;/* Ensures consistent vertical alignment */
		white-space: normal;/* Ensures consistent row-break*/
	}
    source-link-text {
        font-size: 14px !important;
        color: #2e2e2e !important;
    }
	
	@media (max-width: 768px) {
		table {
			width: 100% !important;/* Ensures the max width */
			table-layout: fixed;/* Ensures fixed table layout */
			overflow: hidden; display: table !important;/* Ensures fixed table layout minimum width */
		}
		td:first-child {
			width: auto !important;/* Ensures that all content is visible */
			max-width: 30% !important;/* Ensures that the coloumn doesnt take up to much space */
			vertical-align: top;/* Ensures consistent vertical alignment */
			white-space: normal !important;/* Prevent word wrapping */
			overflow: hidden !important;/* Hide overflow if text is too long */
		}
	}

</style>

<table>
	<tbody>
		<tr>
			<td><strong>Introduction:</strong></td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td>
				<br>
			</td>
			<td>
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Date Signed:</strong></td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Place signed:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Southeast Asian treaty party:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Imperial treaty party:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Additional treaty parties:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Present countries:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Languages:</strong></td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Scripts:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td>
				<br>
			</td>
			<td>
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Recommended reference:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Research notes:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Related treaties:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Further reading:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Source documents:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Acknowledgements:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
		<tr>
			<td><strong>Documents:</strong>
				<br>
			</td>
			<td>N/A
				<br>
			</td>
		</tr>
	</tbody>
</table>

<p>
    <br>
</p>

<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="title1" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="title1">Title</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box1" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box1">Preamble</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Articles 1 to 60 -->
<!-- Article 1 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box2" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box2">Article 1</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
        <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 2 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box3" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box3">Article 2</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 3 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box4" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box4">Article 3</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 4 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box5" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box5">Article 4</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 5 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box6" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box6">Article 5</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 6 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box7" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box7">Article 6</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 7 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box8" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box8">Article 7</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 8 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box9" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box9">Article 8</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 9 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box10" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box10">Article 9</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 10 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box11" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box11">Article 10</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 11 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box12" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box12">Article 11</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 12 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box13" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box13">Article 12</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 13 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box14" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box14">Article 13</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 14 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box15" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box15">Article 14</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 15 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box16" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box16">Article 15</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 16 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box17" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box17">Article 16</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 17 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box18" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box18">Article 17</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 18 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box19" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box19">Article 18</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 19 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box20" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box20">Article 19</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 20 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box21" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box21">Article 20</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 21 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box22" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box22">Article 21</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 22 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box23" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box23">Article 22</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 23 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box24" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box24">Article 23</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 24 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box25" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box25">Article 24</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 25 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box26" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box26">Article 25</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 26 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box27" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box27">Article 26</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 27 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box28" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box28">Article 27</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 28 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box29" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box29">Article 28</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 29 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box30" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box30">Article 29</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 30 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box31" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box31">Article 30</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 31 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box32" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box32">Article 31</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 32 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box33" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box33">Article 32</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 33 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box34" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box34">Article 33</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 34 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box35" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box35">Article 34</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 35 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box36" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box36">Article 35</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 36 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box37" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box37">Article 36</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 37 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box38" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box38">Article 37</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 38 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box39" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box39">Article 38</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 39 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box40" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box40">Article 39</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 40 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box41" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box41">Article 40</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 41 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box42" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box42">Article 41</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 42 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box43" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box43">Article 42</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 43 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box44" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box44">Article 43</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 44 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box45" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box45">Article 44</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 45 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box46" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box46">Article 45</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 46 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box47" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box47">Article 46</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 47 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box48" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box48">Article 47</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 48 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box49" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box49">Article 48</label>
    </h2>
    <div class="content-article-box">

        <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 49 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box50" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box50">Article 49</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 50 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box51" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box51">Article 50</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 51 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box52" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box52">Article 51</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 52 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box53" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box53">Article 52</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 53 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box54" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box54">Article 53</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 54 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box55" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box55">Article 54</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 55 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box56" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box56">Article 55</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 56 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box57" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box57">Article 56</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 57 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box58" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box58">Article 57</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 58 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box59" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box59">Article 58</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 59 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box60" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box60">Article 59</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Article 60 -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="box61" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="box61">Article 60</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Final Clauses -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="FC" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="FC">Final Clauses</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Signatures -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="sig" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="sig">Signatures</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Additional Articles -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="AA" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="AA">Additional Articles</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>

<!-- Additional Notes -->
<section class="accordion-article-box">
    <input type="checkbox" name="collapse" id="AN" class="changelog-check">

    <h2 class="handle-article-box">
        <label for="AN">Additional Notes</label>
    </h2>
    <div class="content-article-box">

       <h3 style="text-align: right;" ><span style="font-size: 12px !important;">
         <span class="source-link-text">Source_Link</span>
      </span></h3>
    </div>
</section>`;
      editor.html.insert(template);
    }

    return { insertTemplate };
  };
};

FE.DefineIconTemplate(
  'treatyTemplateIcon',
  '<svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"/></svg>',
);

FE.DefineIcon('treatyTemplateIcon', { NAME: 'treatyTemplate', template: 'treatyTemplateIcon' });
FE.RegisterCommand('treatyTemplate', {
  title: localizedStrings.global.insertTreatyTemplate,
  icon: 'treatyTemplateIcon',
  undo: false,
  focus: false,
  plugin: 'treatyTemplate',
  callback: function () {
    this.treatyTemplate.insertTemplate();
  },
});
