import { Article, Section } from '../../../Types';
import { sortByOrderIndex } from '../../../util/DataHelpers';
import { CategoryTreeNorm } from './CategoryNavEdit';

export const normalize = (tree: CategoryTreeNorm): CategoryTreeNorm => {
  return {
    ...tree,
    sections: tree.sections.sort(sortByOrderIndex).map((s, i) => ({
      ...s,
      orderIndex: i,
      articles: s.articles.sort(sortByOrderIndex).map((a, i) => ({ ...a, orderIndex: i })),
    })),
  };
};

export const addArticle = (tree: CategoryTreeNorm, newArticle: Article): CategoryTreeNorm => {
  if (!newArticle.section) {
    newArticle.section = 'DEFAULT_SECTION';
  }

  return normalize({
    ...tree,
    sections: tree.sections.map((s) => {
      if (s.fId === newArticle.section) {
        return {
          ...s,
          articles: [newArticle, ...s.articles],
        };
      } else {
        return s;
      }
    }),
  });
};
export const removeArticle = (tree: CategoryTreeNorm, fId: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.map((s) => ({
      ...s,
      articles: s.articles.map((a) => (a.fId === fId ? { ...a, deleted: !a.deleted } : a)),
    })),
  });
};
export const removeArticlePermanent = (tree: CategoryTreeNorm, fId: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.map((s) => ({
      ...s,
      articles: s.articles.filter((a) => a.fId !== fId),
    })),
  });
};
export const editArticle = (tree: CategoryTreeNorm, fId: string, name: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.map((s) => ({
      ...s,
      articles: s.articles.map((a) => (a.fId === fId ? { ...a, name } : a)),
    })),
  });
};

export const addSection = (tree: CategoryTreeNorm, newSection: Section): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: [{ ...newSection, articles: [] }, ...tree.sections],
  });
};
export const sortSections = (tree: CategoryTreeNorm, newSortedSections: Section[]): CategoryTreeNorm => {
  const updatedSections = newSortedSections.map((section) => {
    const existingSection = tree.sections.find((s) => s.fId === section.fId);

    return {
      ...section,

      articles: existingSection ? existingSection.articles : [],
    };
  });

  return normalize({
    ...tree,
    sections: updatedSections,
  });
};
export const editSection = (tree: CategoryTreeNorm, fId: string, name: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.map((s) => (s.fId === fId ? { ...s, name } : s)),
  });
};

export const removeSection = (tree: CategoryTreeNorm, fId: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.map((s) => (s.fId === fId ? { ...s, deleted: !s.deleted } : s)),
  });
};
export const removeSectionPermanent = (tree: CategoryTreeNorm, fId: string): CategoryTreeNorm => {
  return normalize({
    ...tree,
    sections: tree.sections.filter((s) => s.fId !== fId),
  });
};
