import { firestore } from '../../typings/firestore';

export enum ArticleFieldPath {
  Category = 'category',
  Section = 'section',
  orderIndex = 'orderIndex',
  deleted = 'deleted',
}

export interface Article extends firestore.DocumentSnapshot {
  data: () => ArticleData;
}

export interface DateOfSigning {
  day: number | undefined;
  month: number | undefined;
  year: number;
}

export interface ArticleData {
  name: string;
  content: string;
  category: string;
  orderIndex: number;
  section?: string | null;
  deleted?: boolean;
  state?: string;
  lastUpdatedBy?: string;
  signing?: { startDate?: DateOfSigning; endDate?: DateOfSigning };
  location?: { longitude: string; latitude: string };
  tags?: Record<string, string[]>;
}

export const isArticle = (doc?: firestore.DocumentSnapshot): boolean => {
  return doc != null && doc.ref.parent.id.startsWith('article');
};

export interface TagCategory extends firestore.DocumentSnapshot {
  data: () => TagCategoryData;
}

export interface TagValues extends firestore.DocumentSnapshot {
  data: () => TagValuesData;
}

export type TagCategoryData = {
  categoryName: string;
  id: string;
};

export type TagValuesData = {
  categoryId: string;
  tagName: string;
  id: string;
};
