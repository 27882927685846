import FE from 'froala-editor';
import { StaticLocalization } from '../localizedStrings';

let localizedStrings = new StaticLocalization().strings;

export const initCustomArticleTemplate = () => {
  FE.PLUGINS.articleTemplate = (editor) => {
    function insertTemplate() {
      const template = `
      <style>
	table {
		width: 100%; table-layout: fixed;/* Ensures fixed table layout */
		display: table !important;/* Ensures fixed table layout minimum width */
	}
	td {
		width: 33.3333%;/* Each column gets 1/3rd of the total width */
		border: 1px solid #000;/* Optional: adds a border */
		word-wrap: break-word;/* Ensures long text breaks correctly */
		overflow: hidden;/* Ensures content doesn't overflow the cell */
		vertical-align: top;/* Ensures consistent vertical alignment */
		white-space: normal;/* Ensures consistent row-break*/
	}
</style>

<table style="margin-left: 0%; width: 100%;">
	<tbody>
		<tr>
			<td style="background-color: rgb(239, 239, 239);"><span style="font-size: 16px;">Language text</span></td>
			<td style="background-color: rgb(239, 239, 239);"><span style="font-size: 16px;">Language text</span></td>
			<td style="background-color: rgb(239, 239, 239);">
				<br>
			</td>
		</tr>
		<tr>
			<td style="background-color: rgb(255, 255, 255);">Text</td>
			<td style="background-color: rgb(255, 255, 255);">Text</td>
			<td style="background-color: rgb(255, 255, 255);">
				<br>
			</td>
		</tr>
	</tbody>
</table>
<p>
	<br>
</p>
`;
      editor.html.insert(template);
    }

    return { insertTemplate };
  };
};

FE.DefineIconTemplate(
  'articleTemplateIcon',
  '<svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/></svg>',
);

FE.DefineIcon('articleTemplateIcon', { NAME: 'articleTemplate', template: 'articleTemplateIcon' });
FE.RegisterCommand('articleTemplate', {
  title: localizedStrings.global.insertArticleTemplate,
  icon: 'articleTemplateIcon',
  undo: false,
  focus: false,
  plugin: 'articleTemplate',
  callback: function () {
    this.articleTemplate.insertTemplate();
  },
});
