import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSearch } from '../../ContextProviders/SearchContext';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { useArticles, useTagCategoris, useTagValues } from '../../ContextProviders/AppContext';
import { Article, TagCategory, TagValues } from '../../../Types';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { Detail } from 'react-calendar/dist/cjs/shared/types';
import Calendar from 'react-calendar';
import { Pagination, SearchResult } from './SearchPage';
import { IconButton } from '../../Buttons/Buttons';
import { faFileDownload, faMap, faSearch, faTimeline, faTrash } from '@fortawesome/pro-solid-svg-icons';
import MultiSelect from '../../Multiselect/Multiselect';
import { Chip } from '../Settings/TagsManager';
import elasticlunr from 'elasticlunr';

import './SearchPage.scss';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { toCSV } from '../../../util/DataHelpers';
import TimelineComponent from './Timeline';
import MapComponent from './MapComponent';
import { useStyle } from '../../ContextProviders/Style';
import { DateOfSigning } from '@eir/core';

interface AdvancedSearchPageProps {
  searchText: string;
}

const AdvancedSearchPage: FunctionComponent = () => {
  const { searchText: routeSearch } = useParams<AdvancedSearchPageProps>();

  const searchText = routeSearch !== undefined ? routeSearch : '';
  const [results, setResults] = useState<elasticlunr.SearchResults[]>([]);
  const [query, setQuery] = useState<string>(searchText);
  const search = useSearch(query);

  const tagCategoriesDocs = useTagCategoris();
  const tagValuesDocs = useTagValues();
  const localization = useLocalization();
  const articles = useArticles();
  const project = useConcreteProject();
  const style = useStyle();

  const [tagCategories, setTagCategories] = useState<TagCategory[]>();
  const [values, setValues] = useState<TagValues[]>([]);
  const [selectedTags, setSelectedTags] = useState<Record<string, string[]> | undefined>();

  const [range, setRange] = useState<boolean>(false);
  const [format, setFormat] = useState<Detail>('month');
  const [dateOfSigning, setDateOfSigning] = useState<Date>();

  const [resultArticles, setresultArticles] = useState<Article[]>([]);
  const [page, setPage] = useState(0);

  const [articlesWithCoordinates, setArticlesWithCoordinates] = useState<Article[]>([]);
  const [articlesWithDates, setArticlesWithDates] = useState<Article[]>([]);
  const [isMapOpened, setIsMapOpened] = useState<boolean>(false);
  const [isTimelineOpened, setIsTimelineOpened] = useState<boolean>(false);

  useEffect(() => {
    setTagCategories(tagCategoriesDocs.docs);
    setValues(tagValuesDocs.docs);
  }, [tagCategoriesDocs, tagValuesDocs]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setResults(search);
  };

  const selectTagValue = useCallback((event) => {
    setSelectedTags((prev) => ({ ...prev, ...event }));
  }, []);

  function containsAllElements(selectedTags: Record<string, string[]>, articleTags: Record<string, string[]>): boolean {
    return Object.values(selectedTags)
      .flat(2)
      .every((item) => Object.values(articleTags).flat(2).includes(item));
  }

  const tagsFiltering = (articles: Article[]): Article[] => {
    if (!selectedTags || Object.entries(selectedTags).length === 0) {
      return articles;
    }

    return articles.filter((article) => {
      const articleTags = article.tags;
      return articleTags !== undefined && containsAllElements(selectedTags, articleTags);
    });
  };

  const makeDateObject = (dateObject: DateOfSigning | undefined): Date => {
    const dateString = [dateObject?.year, dateObject?.month ?? '01', dateObject?.day ?? '01'].join('/');
    return new Date(dateString);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setResults(search);

    let res: Article[] = [];
    const articleIds = results.map((r) => r.ref);
    const articlesSearched = articles.docs.filter((a) => articleIds.includes(a.fId));

    if (selectedTags) {
      res = tagsFiltering(articlesSearched);
    } else {
      res = articlesSearched;
    }

    if (query === '' && selectedTags) {
      res = tagsFiltering(articles.docs);
    }

    if (dateOfSigning) {
      const temp = res;

      if (!range) {
        res = temp.filter((a) => {
          const articleStart = makeDateObject(a.signing?.startDate);
          const articleEnd = a.signing?.endDate ? makeDateObject(a.signing?.endDate) : articleStart;

          if (format === 'decade') {
            return (
              articleStart.getFullYear() === dateOfSigning.getFullYear() &&
              articleEnd.getFullYear() === dateOfSigning.getFullYear()
            );
          } else if (format === 'year') {
            return (
              articleStart.getFullYear() <= dateOfSigning.getFullYear() &&
              articleEnd.getFullYear() >= dateOfSigning.getFullYear() &&
              articleStart.getMonth() <= dateOfSigning.getMonth() &&
              articleEnd.getMonth() >= dateOfSigning.getMonth()
            );
          } else {
            return articleStart.getTime() <= dateOfSigning.getTime() && articleEnd.getTime() >= dateOfSigning.getTime();
          }
        });
      } else {
        const selectedStart = dateOfSigning[0];
        const selectedEnd = dateOfSigning[1];

        res = temp.filter((a) => {
          const signingStart = makeDateObject(a.signing?.startDate);
          const signingEnd = a.signing?.endDate ? makeDateObject(a.signing?.endDate) : signingStart;

          if (format === 'decade') {
            return (
              selectedStart.getFullYear() <= signingStart.getFullYear() &&
              selectedEnd.getFullYear() >= signingEnd.getFullYear()
            );
          } else if (format === 'year') {
            return (
              selectedStart.getFullYear() <= signingStart.getFullYear() &&
              selectedStart.getMonth() <= signingStart.getMonth() &&
              selectedEnd.getFullYear() >= signingEnd.getFullYear() &&
              selectedEnd.getMonth() >= signingEnd.getMonth()
            );
          } else {
            return selectedStart.getTime() <= signingStart.getTime() && selectedEnd.getTime() >= signingEnd.getTime();
          }
        });
      }
    }

    setArticlesWithCoordinates(res.filter((r) => r.location !== undefined && r.location.latitude !== ''));
    setArticlesWithDates(res.filter((r) => r.signing !== undefined && r.signing.startDate !== undefined));
    setresultArticles(res);
  };

  const clearSearch = () => {
    setSelectedTags({});
    setDateOfSigning(undefined);
    setQuery('');
    setResults([]);
    setresultArticles([]);
    setArticlesWithDates([]);
    setArticlesWithCoordinates([]);
    setRange(false);
  };

  const printFormattedDate = (date: Date) => {
    if (format === 'month') {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } else if (format === 'year') {
      return `${date.getMonth() + 1}/${date.getFullYear()}`;
    } else {
      return `${date.getFullYear()}`;
    }
  };

  const printDate = () => {
    if (range) {
      if (dateOfSigning && dateOfSigning[0]) {
        return `${printFormattedDate(dateOfSigning[0])} - ${
          dateOfSigning[1] ? printFormattedDate(dateOfSigning[1]) : ''
        }`;
      }
      return;
    } else {
      return `${dateOfSigning && printFormattedDate(dateOfSigning)}`;
    }
  };

  const exportSearchResultsCSVFile = () => {
    const tags = selectedTags
      ? Object.values(selectedTags).map((v) =>
          v.map((value, index) => {
            const matchingTag = values.find((el) => el.fId === value);
            return matchingTag?.tagName;
          }),
        )
      : [];

    const blob = new Blob(
      [
        toCSV(
          [
            { keyword: query, tags: tags.flat().join('; ') },
            { keyword: '', tags: '\n' },
          ],
          {
            keyword: 'Keyword',
            tags: 'Tags',
          },
        ),

        toCSV(
          resultArticles.map(({ name = '', category = '', fId = '' }) => {
            return {
              name,
              link: `${window.origin}/category/${category}/${fId}`,
            };
          }),
          {
            name: 'Article Name',
            link: 'Link',
          },
        ),
      ],
      { type: 'text/csv' },
    );

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.fullName} Search Results.csv`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="search-page">
        <div className="search-results-for d-flex justify-content-between">
          <div style={{ width: '100%' }}>
            <h2>{localization.strings.search.advancedSearch}</h2>
            <Form>
              <FormGroup>
                <Input
                  placeholder={localization.strings.global.search}
                  value={query}
                  onChange={handleInputChange}
                ></Input>
              </FormGroup>
              <InputGroup style={{ gap: 20 }}>
                {tagCategories?.map((cat, index) => (
                  <div key={index} style={{ maxWidth: 200 }}>
                    <MultiSelect
                      options={values
                        .filter((val) => val.categoryId === cat.fId)
                        .map((tagValue) => ({
                          name: tagValue.tagName,
                          value: tagValue.fId,
                          category: cat.fId,
                          key: tagValue.fId,
                        }))}
                      onChange={selectTagValue}
                      name={cat.categoryName}
                      defaultValues={selectedTags ? selectedTags : {}}
                    />
                  </div>
                ))}
              </InputGroup>
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {selectedTags &&
                  Object.values(selectedTags).map((v) =>
                    v.map((value, index) => {
                      const matchingTag = values.find((el) => el.fId === value);
                      return matchingTag ? <Chip key={index} name={matchingTag.tagName} /> : null;
                    }),
                  )}
              </div>
              <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30, marginTop: 20 }}>
                <div>
                  <Calendar
                    allowPartialRange={range}
                    maxDetail={format}
                    selectRange={range}
                    showWeekNumbers={false}
                    onChange={(val: Date) => setDateOfSigning(val)}
                    value={dateOfSigning}
                  />
                </div>
                <div>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      onChange={() => {
                        setRange((prev) => !prev);
                        setDateOfSigning(new Date());
                      }}
                      style={{
                        backgroundColor: `${range ? style.primary : '#fff'}`,
                        borderColor: `${range ? style.primary : '#000'}`,
                      }}
                    ></Input>
                    <Label check>{localization.strings.article.articleInfo.dateRange}</Label>
                  </FormGroup>

                  <FormGroup tag={'fieldset'}>
                    <p className="my-2">{localization.strings.article.articleInfo.dateFormat}: </p>{' '}
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="dateFormat"
                        onClick={() => setFormat('month')}
                        defaultChecked
                        style={{
                          backgroundColor: `${format === 'month' ? style.primary : '#fff'}`,
                          borderColor: `${format === 'month' ? style.primary : '#000'}`,
                        }}
                      />
                      <Label check>dd/mm/yyyy</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="dateFormat"
                        onClick={() => setFormat('year')}
                        style={{
                          backgroundColor: `${format === 'year' ? style.primary : '#fff'}`,
                          borderColor: `${format === 'year' ? style.primary : '#000'}`,
                        }}
                      />
                      <Label check>mm/yyyy</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="dateFormat"
                        onClick={() => setFormat('decade')}
                        style={{
                          backgroundColor: `${format === 'decade' ? style.primary : '#fff'}`,
                          borderColor: `${format === 'decade' ? style.primary : '#000'}`,
                        }}
                      />
                      <Label check>yyyy</Label>
                    </FormGroup>
                  </FormGroup>

                  {dateOfSigning ? <p>{printDate()}</p> : null}
                </div>
              </FormGroup>

              <IconButton
                theme={'dark'}
                icon={faSearch}
                text={localization.strings.global.search}
                onClick={handleSearch}
              ></IconButton>
              <IconButton icon={faTrash} text={'Clear'} onClick={clearSearch}></IconButton>
            </Form>
          </div>
        </div>
        <div className="search-results-list">
          <hr className="mb-4" />
          {resultArticles.length > 0 && (
            <IconButton
              theme="dark"
              icon={faFileDownload}
              text={localization.strings.search.downlad}
              onClick={exportSearchResultsCSVFile}
            ></IconButton>
          )}
          {articlesWithCoordinates.length > 0 && (
            <IconButton
              theme={`${isMapOpened ? 'light' : 'dark'}`}
              icon={faMap}
              text={localization.strings.search.map}
              onClick={() => setIsMapOpened((prev) => !prev)}
              style={{ border: `${isMapOpened ? '1px solid #000' : 'none'}` }}
            ></IconButton>
          )}

          {project.enableTimeline && articlesWithDates.length > 0 && (
            <IconButton
              theme={`${isTimelineOpened ? 'light' : 'dark'}`}
              icon={faTimeline}
              text={localization.strings.search.timeline}
              onClick={() => setIsTimelineOpened((prev) => !prev)}
              style={{ border: `${isTimelineOpened ? '1px solid #000' : 'none'}` }}
            ></IconButton>
          )}
          {isMapOpened && articlesWithCoordinates.length > 0 && <MapComponent articles={articlesWithCoordinates} />}
          {project.enableTimeline && isTimelineOpened && articlesWithDates.length > 0 && (
            <TimelineComponent articles={articlesWithDates} />
          )}

          {resultArticles.slice(page * 10, page * 10 + 10).map((a) => (
            <SearchResult id={a.fId} key={a.fId} />
          ))}
        </div>
        <Pagination resultArticles={resultArticles} setPage={setPage} page={page} />
      </div>
    </>
  );
};

export default AdvancedSearchPage;
