import { useCallback, useEffect, useState } from 'react';
import { Article, TagCategory, TagValues } from '../../Types';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { useFirestore } from '../ContextProviders/Firebase';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import { useArticle, useTagCategoris, useTagValues } from '../ContextProviders/AppContext';
import { tagValueAction } from '../../Hooks/DatabaseActions';
import { toasts } from '../../shared';
import { Chip } from '../Pages/Settings/TagsManager';
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { IconButton } from '../Buttons/Buttons';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import Calendar from 'react-calendar';
import { Detail } from 'react-calendar/dist/cjs/shared/types';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { Icon, LatLngLiteral } from 'leaflet';
import MultiSelect from '../Multiselect/Multiselect';

import './ArticleInfo.scss';
import 'react-calendar/dist/Calendar.css';
import 'leaflet/dist/leaflet.css';

interface ArticleInfoProps {
  id?: string;
  editing?: boolean;
}

interface LocationFromMapProps {
  inputPos: LatLngLiteral | undefined;
  getPositionFromClick: (pos: LatLngLiteral) => void;
  disableClick?: boolean;
}

function LocationFromMap({ getPositionFromClick, inputPos, disableClick }: LocationFromMapProps) {
  const [position, setPosition] = useState<LatLngLiteral>();
  const map = useMap();

  const legalIcon = new Icon({
    iconUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
    iconSize: [20, 30],
  });

  useEffect(() => {
    if (inputPos !== undefined) {
      setPosition({ lat: inputPos.lat, lng: inputPos.lng });
      map.flyTo({ lat: inputPos.lat, lng: inputPos.lng });
    }
  }, [inputPos, map]);

  useEffect(() => {
    let handleClick;

    if (!disableClick) {
      handleClick = (e) => {
        setPosition(e.latlng);
        if (getPositionFromClick) {
          getPositionFromClick(e.latlng);
        }
        map.flyTo(e.latlng);
      };

      map.on('click', handleClick);
    }

    return () => {
      if (handleClick) {
        map.off('click', handleClick);
      }
    };
  }, [map, getPositionFromClick, disableClick]);

  return position ? (
    <Marker position={position} icon={legalIcon}>
      <Popup>{`Latitude: ${position.lat}, Longitude: ${position.lng}`}</Popup>
    </Marker>
  ) : null;
}

const ArticleInfo = ({ id, editing }: ArticleInfoProps) => {
  const tagCategoriesDocs = useTagCategoris();
  const tagValuesDocs = useTagValues();
  const concreteProject = useConcreteProject();
  const firestore = useFirestore();
  const localization = useLocalization();
  const currentArticle = useArticle(id);

  const [tagCategories, setTagCategories] = useState<TagCategory[]>();
  const [values, setValues] = useState<TagValues[]>([]);

  const [currentTags, setCurrentTags] = useState<Record<string, string[]> | undefined>(currentArticle?.tags);
  const [selectedTags, setSelectedTags] = useState<Record<string, string[]> | undefined>(currentArticle?.tags);

  const [range, setRange] = useState<boolean>(false);
  const [format, setFormat] = useState<Detail>('month');

  const [dateOfSigning, setDateOfSigning] = useState<Date>();

  const [latitude, setLatitude] = useState<string | undefined>(currentArticle?.location?.latitude);
  const [longitude, setLongitude] = useState<string | undefined>(currentArticle?.location?.longitude);

  const tagAction = tagValueAction(firestore, concreteProject.id);

  const regexLat = /^-?([0-8]?\d(\.\d{1,3})?|90(\.0{1,2})?)$/;
  const regexLng = /^-?(1[0-7]\d(\.\d{1,3})?|180(\.0{1,2})?|[1-9]?\d(\.\d{1,3})?)$/;

  useEffect(() => {
    setTagCategories(tagCategoriesDocs.docs);
    setValues(tagValuesDocs.docs);
    if (currentArticle?.tags) setCurrentTags(currentArticle.tags);
  }, [tagCategoriesDocs, tagValuesDocs, currentArticle]);

  const formatDate = (date: Date) => {
    if (format === 'decade') {
      return { year: date.getFullYear() };
    } else if (format === 'year') {
      return { year: date.getFullYear(), month: date.getMonth() + 1 };
    } else {
      return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }
  };

  const saveArticleInfo = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const dataObj: {
      tags?: Record<string, string[]> | undefined;
      signing?: {
        startDate?: { day?: number; month?: number; year?: number };
        endDate?: { day?: number; month?: number; year?: number };
      };
      location?: { latitude: string; longitude: string };
    } = {};

    if (selectedTags && Object.keys(selectedTags).length > 0) {
      dataObj.tags = selectedTags;
    }

    if (dateOfSigning !== undefined) {
      if (range) {
        dataObj.signing = { startDate: formatDate(dateOfSigning[0]), endDate: formatDate(dateOfSigning[1]) };
      } else {
        dataObj.signing = { startDate: formatDate(dateOfSigning) };
      }
    } else {
      dataObj.signing = { ...currentArticle?.signing };
    }

    dataObj.location = { latitude: latitude ? latitude : '', longitude: longitude ? longitude : '' };

    if (id) {
      try {
        await tagAction.addTagsToArticle(id, dataObj);
        toasts.success(localization.strings.article.articleInfo.articleInfoSaveSucceess);
        setSelectedTags({});
      } catch (err) {
        console.error('Error while adding tags: ', err);
        toasts.error(localization.strings.article.articleInfo.articleInfoSaveFailed);
      }
    }
  };

  const selectTagValue = useCallback((event) => {
    setSelectedTags((prev) => ({ ...prev, ...event }));
  }, []);

  const getPositionFromClick = (pos: LatLngLiteral) => {
    setLatitude(String(pos.lat.toFixed(3)));
    setLongitude(String(pos.lng.toFixed(3)));
  };

  return (
    <div
      style={{
        display: 'flex',
        columnGap: 20,
        rowGap: 0,
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'fit-content',
        marginTop: 20,
      }}
    >
      {currentArticle?.tags && (
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <h6 className="my-0">{localization.strings.article.articleInfo.tags}:</h6>
          {currentTags &&
            Object.values(currentTags).map((v) =>
              v.map((value, index) => {
                const matchingTag = values.find((el) => el.fId === value);
                return matchingTag ? <Chip key={index} name={matchingTag.tagName} /> : null;
              }),
            )}
        </div>
      )}

      {editing ? (
        <Form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={saveArticleInfo}
          style={{
            display: 'block',
            columnGap: 20,
            rowGap: 0,
            marginTop: 20,
            width: '100%',
          }}
        >
          <InputGroup style={{ gap: 20 }}>
            {tagCategories?.map((cat, index) => (
              <div key={index} style={{ maxWidth: 200 }}>
                <MultiSelect
                  options={values
                    .filter((val) => val.categoryId === cat.fId)
                    .map((tagValue) => ({ name: tagValue.tagName, value: tagValue.fId, category: cat.fId }))}
                  onChange={selectTagValue}
                  name={cat.categoryName}
                  defaultValues={currentArticle?.tags}
                />
              </div>
            ))}
          </InputGroup>
          <hr />

          <div style={{ display: 'flex', flexDirection: 'row', columnGap: 50, flexWrap: 'wrap' }}>
            <div>
              <h6>{localization.strings.article.articleInfo.dateOfSigning}</h6>
              <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30, marginTop: 20 }}>
                <div>
                  <Calendar
                    allowPartialRange={range}
                    maxDetail={format}
                    selectRange={range}
                    showWeekNumbers={false}
                    onChange={(val: Date) => setDateOfSigning(val)}
                  />
                </div>
                <div>
                  <FormGroup switch>
                    <Input type="switch" role="switch" onChange={() => setRange((prev) => !prev)}></Input>
                    <Label check>{localization.strings.article.articleInfo.dateRange}</Label>
                  </FormGroup>

                  <FormGroup tag={'fieldset'}>
                    <p className="my-2">{localization.strings.article.articleInfo.dateFormat}: </p>{' '}
                    <FormGroup check>
                      <Input type="radio" name="dateFormat" onClick={() => setFormat('month')} defaultChecked />
                      <Label check>dd/mm/yyyy</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="radio" name="dateFormat" onClick={() => setFormat('year')} />
                      <Label check>mm/yyyy</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="radio" name="dateFormat" onClick={() => setFormat('decade')} />
                      <Label check>yyyy</Label>
                    </FormGroup>
                  </FormGroup>
                </div>
              </FormGroup>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: 50, flexWrap: 'wrap' }}>
              <div>
                <h6>{localization.strings.article.articleInfo.location}</h6>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30, margin: '20px 0' }}
                >
                  <div style={{ height: 310, maxWidth: 320, width: '100%', zIndex: 0 }}>
                    <MapContainer
                      center={latitude ? [Number(latitude), Number(longitude)] : [62.38583179, 16.321998712]}
                      zoom={latitude ? 12 : 4}
                      scrollWheelZoom={true}
                      minZoom={2}
                      maxZoom={9.5}
                      style={{ height: '100%', width: 320 }}
                      dragging={true}
                    >
                      <LocationFromMap
                        getPositionFromClick={getPositionFromClick}
                        inputPos={latitude && longitude ? { lat: Number(latitude), lng: Number(longitude) } : undefined}
                      />
                      <TileLayer
                        attribution="Esri"
                        className="basemap"
                        subdomains={['clarity']}
                        noWrap={true}
                        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      />
                    </MapContainer>
                  </div>
                  <FormGroup style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '00px 0' }}>
                    <InputGroup>
                      <InputGroupText>{localization.strings.article.articleInfo.latitude} </InputGroupText>
                      <Input
                        type="number"
                        name="latitude"
                        value={latitude}
                        onChange={(event) => setLatitude(event.target.value)}
                        placeholder={localization.strings.article.articleInfo.latitude}
                        invalid={latitude !== undefined && latitude.length > 0 && !regexLat.test(latitude)}
                      ></Input>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupText>{localization.strings.article.articleInfo.longitude}</InputGroupText>
                      <Input
                        type="number"
                        name="longitude"
                        value={longitude}
                        onChange={(event) => setLongitude(event.target.value)}
                        placeholder={localization.strings.article.articleInfo.longitude}
                        invalid={longitude !== undefined && longitude.length > 0 && !regexLng.test(longitude)}
                      ></Input>
                    </InputGroup>
                  </FormGroup>
                </FormGroup>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', marginTop: 20 }}>
            <IconButton
              icon={faSave}
              text={localization.strings.article.articleInfo.saveInfo}
              type={'submit'}
              color={'primary'}
            ></IconButton>
          </div>
          <hr />
        </Form>
      ) : (
        <>
          {currentArticle?.signing?.startDate && (
            <div>
              <p>
                {localization.strings.article.articleInfo.signedOn}: {printDate(currentArticle)}
              </p>
            </div>
          )}

          {currentArticle?.location && (
            <div>
              {latitude !== '' && longitude !== '' && (
                <div style={{ height: 310, maxWidth: 320, width: '100%', zIndex: 0 }}>
                  <MapContainer
                    center={[Number(latitude), Number(longitude)]}
                    zoom={3}
                    scrollWheelZoom={true}
                    minZoom={2}
                    maxZoom={9.5}
                    style={{ height: '100%', width: 320 }}
                    dragging={true}
                  >
                    <LocationFromMap
                      getPositionFromClick={() => undefined}
                      disableClick={true}
                      inputPos={{ lat: Number(latitude), lng: Number(longitude) }}
                    />
                    <TileLayer
                      attribution="Esri"
                      className="basemap"
                      subdomains={['clarity']}
                      noWrap={true}
                      url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                  </MapContainer>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArticleInfo;

export const printDate = (article: Article) => {
  const start = article?.signing?.startDate;
  const end = article?.signing?.endDate;

  if (!start) {
    return 'Unknown';
  }

  if (end) {
    return `${start?.day ? String(start?.day) + '/' : ''}${start?.month ? String(start.month) + '/' : ''}${
      start?.year
    } - ${end?.day ? String(end?.day) + '/' : ''}${end?.month ? String(end.month) + '/' : ''}${end?.year}`;
  }
  return `${start?.day ? String(start?.day) + '/' : ''}${start?.month ? String(start.month) + '/' : ''}${start?.year}`;
};
