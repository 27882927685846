import { Firestore } from 'firebase/firestore';
import React from 'react';
import {
  articleActions,
  changelogArticleActions,
  changelogSectionActions,
  chiefArticleActions,
  chiefSectionActions,
  sectionActions,
} from '../../../Hooks/DatabaseActions';
import { Article, Section } from '../../../Types';
import { AppUser } from '../../ContextProviders/Auth';
import { CategoryTreeNorm } from './CategoryNavEdit';
import {
  addArticle,
  addSection,
  editArticle,
  editSection,
  removeArticle,
  removeArticlePermanent,
  removeSection,
  removeSectionPermanent,
  sortSections,
} from './treeUtil';

export interface TreeFunctions {
  addSection: (name: string) => Promise<void>;
  autoSortSection: () => Promise<void>;
  addArticle: (name: string, sectionId: string, currentUser?: string | null) => Promise<void>;
  removeSection: (section: Section) => Promise<void>;
  removeArticle: (article: Article, currentUser?: string | null) => Promise<void>;
  syncVanishedArticle?: (article: Article) => Promise<void>;
  syncVanishedSection?: (section: Section) => Promise<void>;
  editSection: (section: Section, name: string) => Promise<void>;
  editArticle: (article: Article, name: string, currentUser?: string) => Promise<void>;
}
export const standardCategoryTreeFunctions = (
  firestore: Firestore,
  projectId: string,
  categoryId: string,
  auth: AppUser,
  setTreeState: React.Dispatch<React.SetStateAction<CategoryTreeNorm | undefined>>,
): TreeFunctions => ({
  addSection: (name: string): Promise<void> => {
    return sectionActions(firestore, projectId)
      .create({ name, category: categoryId, orderIndex: -1 })
      .then((section) => {
        setTreeState((treeState) => {
          if (!treeState) return treeState;
          return addSection(treeState, section);
        });
      });
  },
  autoSortSection: (): Promise<void> => {
    return sectionActions(firestore, projectId)
      .sortAllSections(categoryId)
      .then((sortedSections) => {
        setTreeState((treeState) => {
          if (!treeState) return treeState;
          return sortSections(treeState, sortedSections);
        });
      })
      .catch((error) => {
        console.error('Error in autoSortSection:', error);
      });
  },
  addArticle: (name: string, sectionId: string, currentUser: string | null): Promise<void> => {
    return articleActions(firestore, projectId, currentUser)
      .create({
        name,
        section: sectionId === 'DEFAULT_SECTION' ? null : sectionId,
        content: '',
        category: categoryId,
        orderIndex: -1,
      })
      .then((article) => {
        setTreeState((treeState) => {
          if (!treeState) return treeState;

          return addArticle(treeState, article);
        });
        return;
      });
  },
  removeSection: (section: Section): Promise<void> => {
    const { deleted, fId } = section;
    if (!deleted) {
      return sectionActions(firestore, projectId)
        .remove(fId, auth.user?.uid || '')
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeSection(treeState, fId);
          });
        });
    } else {
      return sectionActions(firestore, projectId)
        .update(fId, { ...section, deleted: false })
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeSection(treeState, fId);
          });
        });
    }
  },
  removeArticle: (article: Article, currentUser: string | null): Promise<void> => {
    const { deleted, fId } = article;
    if (!deleted) {
      return articleActions(firestore, projectId, currentUser)
        .remove(fId, auth.user?.uid || '')
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeArticle(treeState, fId);
          });
        });
    } else {
      return articleActions(firestore, projectId, currentUser)
        .update(fId, { ...article, section: article.section === undefined ? null : article.section, deleted: false })
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeArticle(treeState, fId);
          });
        });
    }
  },
  syncVanishedArticle: (article: Article): Promise<void> => {
    const { fId } = article;
    setTreeState((treeState) => {
      if (!treeState) return treeState;
      return removeArticlePermanent(treeState, fId);
    });
    return Promise.resolve();
  },
  syncVanishedSection: (section: Section): Promise<void> => {
    const { fId } = section;
    setTreeState((treeState) => {
      if (!treeState) return treeState;
      return removeSectionPermanent(treeState, fId);
    });
    return Promise.resolve();
  },
  editSection: (section: Section, name: string): Promise<void> => {
    return sectionActions(firestore, projectId)
      .update(section.fId, { ...section, name })
      .then(() => {
        setTreeState((treeState) => {
          if (!treeState) return treeState;
          return editSection(treeState, section.fId, name);
        });
      });
  },
  editArticle: async (article: Article, name: string, currentUser?: string): Promise<void> => {
    return articleActions(firestore, projectId, currentUser)
      .update(article.fId, {
        section: article.section === undefined || article.section === 'DEFAULT_SECTION' ? null : article.section,
        name,
      })
      .then(() => {
        setTreeState((treeState) => {
          if (!treeState) return treeState;
          return editArticle(treeState, article.fId, name);
        });
      });
  },
});

const chiefChangelogCategoryTreeFunctions = (
  firestore: Firestore,
  projectId: string,
  categoryId: string,
  auth: AppUser,
  setTreeState: React.Dispatch<React.SetStateAction<CategoryTreeNorm | undefined>>,
  isChief: boolean,
): TreeFunctions => {
  const articleActions = isChief ? chiefArticleActions : changelogArticleActions;
  const sectionActions = isChief ? chiefSectionActions : changelogSectionActions;
  return {
    addSection: (name: string): Promise<void> => {
      return sectionActions(firestore, projectId)
        .create({ name, category: categoryId, orderIndex: -1 })
        .then((section) => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return addSection(treeState, section);
          });
        });
    },
    autoSortSection: (): Promise<void> => {
      return sectionActions(firestore, projectId)
        .sortAllSections(categoryId)
        .then((sortedSections) => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return sortSections(treeState, sortedSections);
          });
        })
        .catch((error) => {
          console.error('Error in autoSortSection:', error);
        });
    },
    addArticle: (name: string, sectionId: string): Promise<void> => {
      return articleActions(firestore, projectId)
        .create({
          name,
          section: sectionId === 'DEFAULT_SECTION' ? null : sectionId,
          content: '',
          category: categoryId,
          orderIndex: -1,
        })
        .then((article) => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;

            return addArticle(treeState, article);
          });
          return;
        });
    },
    removeSection: (section: Section): Promise<void> => {
      const { fId } = section;
      return sectionActions(firestore, projectId)
        .remove(fId)
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeSectionPermanent(treeState, fId);
          });
        });
    },
    removeArticle: (article: Article): Promise<void> => {
      const { fId } = article;
      return articleActions(firestore, projectId)
        .remove(fId)
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return removeArticlePermanent(treeState, fId);
          });
        });
    },
    editSection: (section: Section, name: string): Promise<void> => {
      return sectionActions(firestore, projectId)
        .update(section.fId, { ...section, name })
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return editSection(treeState, section.fId, name);
          });
        });
    },
    editArticle: async (article: Article, name: string): Promise<void> => {
      return articleActions(firestore, projectId)
        .update(article.fId, {
          section: article.section === undefined || article.section === 'DEFAULT_SECTION' ? null : article.section,
          name,
        })
        .then(() => {
          setTreeState((treeState) => {
            if (!treeState) return treeState;
            return editArticle(treeState, article.fId, name);
          });
        });
    },
  };
};

export const changelogCategoryTreeFunctions = (
  firestore: Firestore,
  projectId: string,
  categoryId: string,
  auth: AppUser,
  setTreeState: React.Dispatch<React.SetStateAction<CategoryTreeNorm | undefined>>,
) => chiefChangelogCategoryTreeFunctions(firestore, projectId, categoryId, auth, setTreeState, false);
export const chiefCategoryTreeFunctions = (
  firestore: Firestore,
  projectId: string,
  categoryId: string,
  auth: AppUser,
  setTreeState: React.Dispatch<React.SetStateAction<CategoryTreeNorm | undefined>>,
) => chiefChangelogCategoryTreeFunctions(firestore, projectId, categoryId, auth, setTreeState, true);
