/* eslint-disable camelcase */

import { Application } from './application';

export interface ApplicationStyle {
  primary: string;
  primaryDark: string;
  primaryLight: string;
  accent: string;
}

export const style: { [K in Application]: ApplicationStyle } = {
  demo: {
    primary: '#1A1F39',
    primaryDark: '#051d3b',
    primaryLight: '#f9c532',
    accent: '#f9c532',
  },
  slas: {
    primary: '#6e9bd2',
    primaryDark: '#55769e',
    primaryLight: '#FFFFFF',
    accent: '#FFFFFF',
  },
  akutmottagningen_kristianstad: {
    primary: '#6cc495',
    primaryDark: '#6cc495',
    primaryLight: '#F8F8F8',
    accent: '#FFFFFF',
  },
  ambulans_blekinge: {
    primary: '#215287',
    primaryDark: '#1d4877',
    primaryLight: '#E8EAF6',
    accent: '#ffcc00',
  },
  ambulans_jonkoping: {
    primary: '#b1063a',
    primaryDark: '#b1063a',
    primaryLight: '#DF9BB0',
    accent: '#FFFFFF',
  },
  ambulans_orebro: {
    primary: '#0067B2',
    primaryDark: '#004F9E',
    primaryLight: '#B2DEF2',
    accent: '#5AB031',
  },
  ambulans_vastmanland: {
    primary: '#1f49a1',
    primaryDark: '#103a90',
    primaryLight: '#f8dd00',
    accent: '#f8dd00',
  },
  ambulans_sahlgrenska: {
    primary: '#006298',
    primaryDark: '#006298',
    primaryLight: '#e5eff4',
    accent: '#ffffff',
  },
  ambulans_halland: {
    primary: '#006858',
    primaryDark: '#006858',
    primaryLight: '#E5F2E5',
    accent: '#ffffff',
  },
  ambulans_skane: {
    primary: '#1F8565',
    primaryDark: '#1F8565',
    primaryLight: '#C8E4DB',
    accent: '#ffffff',
  },
  ambulans_ssih_sormland: {
    primary: '#9D1458',
    primaryDark: '#87124d',
    primaryLight: '#fce8f2',
    accent: '#E9EF4B',
  },
  lakemedel_gavleborg: {
    primary: '#50B848',
    primaryDark: '#388E3C',
    primaryLight: '#E8F5E9',
    accent: '#4058FF',
  },
  polis_appen: {
    primary: '#163c6b',
    primaryDark: '#051d3b',
    primaryLight: '#f9c532',
    accent: '#f9c532',
  },
  strama_gavleborg: {
    primary: '#FFC107',
    primaryDark: '#FFA000',
    primaryLight: '#FFFDE7',
    accent: '#EB1F21',
  },
  strama_nationell: {
    primary: '#5c92c4',
    primaryDark: '#1E88E5',
    primaryLight: '#E3F2FD',
    accent: '#FFD82B',
  },
  ambulans_vasternorrland: {
    primary: '#009fe3',
    primaryDark: '#1E88E5',
    primaryLight: '#E3F2FD',
    accent: '#ffcc00',
  },
  ambulans_norrbotten: {
    primary: '#C73238',
    primaryDark: '#B2292E',
    primaryLight: '#E3F2FD',
    accent: '#E3F2FD',
  },
  ambulans_sormland: {
    primary: '#9D1458',
    primaryDark: '#87124d',
    primaryLight: '#fce8f2',
    accent: '#E9EF4B',
  },
  raddningstjansten_syd: {
    primary: '#103250',
    primaryDark: '#103250',
    primaryLight: '#fffcf0',
    accent: '#f1b600',
  },
  softwerk: {
    primary: '#B72C25',
    primaryDark: '#080201',
    primaryLight: '#ffff',
    accent: '#ffff',
  },
  ambulans_kronoberg: {
    primary: '#006633',
    primaryDark: '#003a0b',
    primaryLight: '#ffff',
    accent: '#E9EF4B',
  },
  ambulans_ostergotland: {
    primary: '#0066B3',
    primaryDark: '#0066B3',
    primaryLight: '#a9d7ff',
    accent: '#fed765',
  },
  ambulans_vasterbotten: {
    primary: '#0050A0',
    primaryDark: '#0050A0',
    primaryLight: '#DCE7F6',
    accent: '#ffffff',
  },
  falu_kommun: {
    primary: '#a63822',
    primaryDark: '#571204',
    primaryLight: '#f3e1dd',
    accent: '#ffffff',
  },
  primarvard_sormland: {
    primary: '#9D1458',
    primaryDark: '#87124d',
    primaryLight: '#fce8f2',
    accent: '#E9EF4B',
  },
  internmedicin_helsingborg: {
    primary: '#ED0025',
    primaryDark: '#ED0025',
    primaryLight: '#ffd9df',
    accent: '#FFFFFF',
  },
  raddsamy: {
    primary: '#104C90',
    primaryDark: '#104C90',
    primaryLight: '#e7edf3',
    accent: '#FFFFFF',
  },
  test_project: {
    primary: '#104C90',
    primaryDark: '#104C90',
    primaryLight: '#e7edf3',
    accent: '#FFFFFF',
  },
  akutkliniken_nykoping: {
    primary: '#9D1458',
    primaryDark: '#9D1458',
    primaryLight: '#FCE4E9',
    accent: '#FFFFFF',
  },
  samariten_gotland: {
    primary: '#DE0D13',
    primaryDark: '#87070b',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  showroom: {
    primary: '#1A1F39',
    primaryDark: '#051d3b',
    primaryLight: '#eaeaea',
    accent: '#ffffff',
  },
  smartyta: {
    primary: '#007EBF',
    primaryDark: '#007EBF',
    primaryLight: '#cfebfa',
    accent: '#ffffff',
  },
  ekg_appen: {
    primary: '#006600',
    primaryDark: '#006600',
    primaryLight: '#ffffff',
    accent: '#64B552',
  },
  urologi_helsingborg: {
    primary: '#09A28F',
    primaryDark: '#09A28F',
    primaryLight: '#D2D70A',
    accent: '#ffffff',
  },
  ambulans_dalarna: {
    primary: '#f15060',
    primaryDark: ' #800015',
    primaryLight: '#ffedf1',
    accent: '#ffffff',
  },
  raddningsregion_mittnorrland: {
    primary: '#1F3460',
    primaryDark: ' #88A2D4',
    primaryLight: '#E4EDFF',
    accent: '#FFFFFF',
  },
  ambulans_stockholm: {
    primary: '#406618',
    primaryDark: '#406618',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  hjartkliniken: {
    primary: '#387d71',
    primaryDark: '#387d71',
    primaryLight: '#d8d1ca',
    accent: '#a89c94',
  },
  strama_latvia: {
    primary: '#1acfc2',
    primaryDark: '#1acfc2',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  ambulans_uppsala: {
    primary: '#005fab',
    primaryDark: '#005fab',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  vultus: {
    primary: '#003333',
    primaryDark: '#003333',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  sea_treaties: {
    primary: '#03400c',
    primaryDark: '#03400c',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  distriktsveterinarerna: {
    primary: '#da2b1f',
    primaryDark: '#da2b1f',
    primaryLight: '#fceae9',
    accent: '#ffffff',
  },
  smedjebackens_kommun: {
    primary: '#9d372a',
    primaryDark: '#9d372a',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },

  sameffekt: {
    primary: '#339a47',
    primaryDark: '#339a47',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  toyama: {
    primary: '#404040',
    primaryDark: '#404040',
    primaryLight: '#ffffff',
    accent: '#ffffff',
  },
  treeid: {
    primary: '#8fc046',
    primaryDark: '#5A752E',
    primaryLight: '#C1E2A5',
    accent: '#FF6347',
  },
};
