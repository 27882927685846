import React, { useState, useRef, useEffect, ChangeEvent, useMemo } from 'react';
import './Multiselect.scss';
import { Input, Label } from 'reactstrap';
import { useLocalization } from '../ContextProviders/LocalizationContext';

export interface Option {
  value: string;
  name?: string;
  category: string;
}

interface MultiSelectProps {
  options: Option[];
  onChange: (selectedOptions: Record<string, string[]>) => void;
  name: string;
  defaultValues?: Record<string, string[]>;
}

const MultiSelect: React.FC<MultiSelectProps> = React.memo(({ options, onChange, name, defaultValues = {} }) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    Object.entries(defaultValues)
      .map((entry) => {
        return entry[1].map((m) => {
          return { value: m, category: entry[0] };
        });
      })
      .flat(),
  );
  const localization = useLocalization();
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Record<string, string[]>>(defaultValues);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const multiSelectRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const clearTags = Object.entries(defaultValues).length > 0;

  useEffect(() => {
    const newSelectedOptions = Object.entries(defaultValues)
      .map((entry) => {
        return entry[1].map((value) => ({
          value,
          category: entry[0],
        }));
      })
      .flat();

    if (!clearTags) {
      setSelectedOptions(newSelectedOptions);
      setSelectedTags(defaultValues);
    }
    // eslint-disable-next-line
  }, [clearTags, setSelectedOptions, setSelectedTags]);

  useEffect(() => {
    onChange(selectedTags);
    const handleMousedown = (e: globalThis.MouseEvent) => {
      if (multiSelectRef.current && !multiSelectRef.current.contains(e.target as Node)) {
        setDropdownActive(false);
      }
    };
    document.addEventListener('mousedown', handleMousedown);
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
    };
  }, [selectedTags, onChange]);

  const handleDropdownClick = () => {
    setDropdownActive(!dropdownActive);
    resetDropdown();
  };

  const resetDropdown = () => {
    if (dropdownActive && textInputRef.current) {
      textInputRef.current.value = '';
      textInputRef.current.focus();
    }
  };

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedItem = options.find((opt) => opt.value === e.target.value);
    let newSelectedOptions: Option[];
    const temp: Record<string, string[]> = selectedTags;

    if (e.target.checked && selectedItem) {
      if (temp && temp[selectedItem.category]) {
        temp[selectedItem.category].push(selectedItem.value);
      } else {
        temp[selectedItem.category] = [selectedItem.value];
      }
      setSelectedTags((prevState) => ({ ...prevState, ...temp }));

      newSelectedOptions = [...selectedOptions, selectedItem];
    } else {
      if (temp) {
        selectedItem?.category &&
          temp[selectedItem.category].splice(temp[selectedItem.category].indexOf(e.target.value), 1);
      }
      newSelectedOptions = selectedOptions.filter((opt) => opt.value !== e.target.value);
    }

    setSelectedOptions(newSelectedOptions);
    onChange(selectedTags);
    textInputRef.current?.focus();
  };

  const isChecked = (value: string) => {
    return !!selectedOptions.find((item) => item.value === value);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredOptions = useMemo(() => {
    return options
      .filter((option) => option.name?.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [options, searchQuery]);

  return (
    <div className={`multiselect-wrapper ${dropdownActive ? 'is-active' : ''}`} ref={multiSelectRef}>
      <div className="multiselect__control" onClick={handleDropdownClick}>
        <span className={`multiselect__arrow-icon fa ${dropdownActive ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
        <h6 className="m-0" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {name}
        </h6>
        <input type="hidden" value={selectedOptions.map((opt) => opt.value).join(',')} name="selectedOptions" />
      </div>
      <div className={`multiselect__result-area ${dropdownActive ? 'is-active' : ''}`}>
        <div className="multiselect-search">
          <Input
            type="text"
            placeholder={localization.strings.settings.tags.search}
            value={searchQuery}
            onChange={handleSearchChange}
            innerRef={textInputRef}
          />
        </div>
        <ul className="multiselect-results">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className={`multiselect-results__item ${isChecked(option.value) ? 'is-active' : ''}`}
            >
              <Input
                type="checkbox"
                onChange={handleOptionChange}
                className="custom-checkbox"
                id={`opt-${option.value}`}
                value={option.value}
                checked={isChecked(option.value)}
              />
              <Label htmlFor={`opt-${option.value}`} className="m-0 custom-checkbox-label">
                {option.name}
              </Label>
            </li>
          ))}
          {filteredOptions.length === 0 && (
            <li className="multiselect-results__item.no-options">
              <Label className="no-options-text">{localization.strings.settings.tags.noTagsFound}</Label>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
});

export default MultiSelect;
