import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './SearchPage.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  useArticle,
  useArticles,
  useArticlesChangelog,
  useCategory,
  useChiefArticles,
  useSection,
  useTagValues,
} from '../../ContextProviders/AppContext';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';
import { useSearch } from '../../ContextProviders/SearchContext';
import { Article } from '../../../Types';
import { toasts } from '../../../shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
import { printDate } from '../../ArticleInfo/ArticleInfo';
import { Chip } from '../Settings/TagsManager';

enum Sort {
  RELEVANCE,
  ALPHA_UP,
  ALPHA_DOWN,
}
interface SearchResultListProps {
  query: string;
  sort?: Sort;
}
export const SearchResultList = ({ query, sort = Sort.RELEVANCE }: SearchResultListProps): ReactElement => {
  const localization = useLocalization();
  const results = useSearch(query);
  const allArticles = [...useArticles().docs, ...useChiefArticles().docs, ...useArticlesChangelog().docs];
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    document.querySelector('.site-body')?.scrollTo(0, 0);
  }, [page]);

  let resultArticles = results
    .map((r) => allArticles.find((a) => a.fId === r.ref))
    .filter((v) => v !== undefined) as Article[];

  switch (sort) {
    case Sort.ALPHA_UP:
      resultArticles = resultArticles.sort((a: Article, b: Article) => (a.name > b.name ? 1 : -1));
      break;
    case Sort.ALPHA_DOWN:
      resultArticles = resultArticles.sort((a: Article, b: Article) => (a.name > b.name ? -1 : 1));
      break;
  }

  if (results.length === 0)
    return (
      <div className="search-results-list ">
        <div className="alert alert-warning">
          <span className="alert h5 ps-2">{localization.strings.search.resultError}</span>
        </div>
      </div>
    );

  return (
    <>
      <div className="search-results-list">
        {resultArticles.slice(page * 10, page * 10 + 10).map((a, i) => (
          <SearchResult id={a.fId} key={a.fId} />
        ))}
      </div>
      <Pagination resultArticles={resultArticles} setPage={setPage} page={page} />
    </>
  );
};

export const Pagination = (props: { resultArticles: Article[]; setPage; page: number }) => {
  const localization = useLocalization();

  const pages = Array(Math.ceil(props.resultArticles.length / 10))
    .fill(1)
    .map((_, i) => (
      <div
        className={`page-clicker ${props.page === i ? 'current-page' : ''}`}
        onClick={() => props.setPage(i)}
        key={i}
      >
        {i + 1}
      </div>
    ));

  const handeChangeNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/, '');
  };

  const writePageNumber = (e) => {
    const pageNumber = parseInt(e.target.value);
    if (e.key === 'Enter') {
      e.preventDefault();
      if (pageNumber < pages.length + 1 && pageNumber !== null && pageNumber > 0) {
        props.setPage(pageNumber - 1);
      } else {
        toasts.error(localization.strings.search.pageNumberError);
      }
      e.target.blur();
      e.target.value = '';
    }
  };

  return (
    <div
      className="pagination-container justify-content-center fixed-bottom"
      style={{ display: pages.length > 1 ? 'flex' : 'none' }}
    >
      <div className="d-flex page-picker justify-content-center py-3">
        <div className="d-inline-flex pagination-row">
          {props.page !== 0 && (
            <div className={`page-clicker`} onClick={() => props.setPage((i) => i - 1)} key="prev">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          )}
          {pages.length > 1 && pages[0]}
          {props.page > 3 && (
            <input
              onChange={handeChangeNumber}
              onKeyPress={writePageNumber}
              onBlur={(e) => (e.target.placeholder = '...')}
              onFocus={(e) => (e.target.placeholder = '')}
              placeholder="..."
            ></input>
          )}
          {pages.slice(Math.max(props.page - 2, 1), Math.min(props.page + 3, pages.length - 1))}
          {props.page < pages.length - 4 && (
            <input
              onChange={handeChangeNumber}
              onKeyPress={writePageNumber}
              onBlur={(e) => (e.target.placeholder = '...')}
              onFocus={(e) => (e.target.placeholder = '')}
              placeholder="..."
            ></input>
          )}
          {pages.length > 1 && pages[pages.length - 1]}
          {pages.length - 1 !== props.page && (
            <div className={`page-clicker`} onClick={() => props.setPage((i: number) => i + 1)} key="next">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SearchResult = ({ id }: { id: string }) => {
  const article = useArticle(id);
  const section = useSection(article?.section);
  const category = useCategory(article?.category);
  const history = useHistory();
  const localization = useLocalization();
  const tagValues = useTagValues().docs;

  if (!article || !category) return <></>;

  return (
    <div className={'search-result'} onClick={() => history.push(`/category/${article.category}/${article.fId}`)}>
      <div className="header">
        {category.name}
        <FontAwesomeIcon icon={faChevronRight} />
        {section ? section.name : 'Ingen Sektion'} <FontAwesomeIcon icon={faChevronRight} />
        <strong>{article.name}</strong>
        <div>
          {article.signing ? (
            <>
              <strong>{localization.strings.article.articleInfo.dateOfSigning}: </strong> {printDate(article)}
            </>
          ) : null}
        </div>
        <div>
          {article.location ? (
            <>
              <strong>{localization.strings.article.articleInfo.location}: </strong>{' '}
              {`${article.location?.latitude} - ${article.location?.longitude}`}
            </>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {article.tags &&
            Object.values(article.tags).map((v) =>
              v.map((value, index) => {
                const matchingTag = tagValues.find((el) => el.fId === value);
                return matchingTag ? <Chip key={index} name={matchingTag.tagName} /> : null;
              }),
            )}
        </div>
      </div>
      <PlatformContentFrame body={article.content} scrolling="no" header={false} />
    </div>
  );
};

interface SearchPageParams {
  searchText: string;
  articleId: string;
}
export const SearchPage = (): ReactElement => {
  const localization = useLocalization();
  const { searchText: routeSearch } = useParams<SearchPageParams>(); //TODO get types for react router hooks
  const searchText = routeSearch !== undefined ? routeSearch : '';
  const [sort, setSort] = useState<Sort>(Sort.RELEVANCE);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const texts = {
    [Sort.RELEVANCE]: localization.strings.search.relevance,
    [Sort.ALPHA_UP]: `${localization.strings.search.alphabetical} (${localization.strings.search.up})`,
    [Sort.ALPHA_DOWN]: `${localization.strings.search.alphabetical} (${localization.strings.search.down})`,
  };

  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${localization.strings.global.search} | ${title}`;
  }, [title, localization.strings.global.search]);

  return (
    <div className="search-page">
      <div className="search-results-for d-flex justify-content-between">
        {searchText === '' ? (
          <h1>{localization.strings.search.searchError}</h1>
        ) : (
          <h1>
            {localization.strings.global.allResults} "{searchText}"
          </h1>
        )}
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => {
            setDropdownOpen((o) => !o);
          }}
        >
          <DropdownToggle caret>{texts[sort]}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setSort(Sort.RELEVANCE)}>{texts[Sort.RELEVANCE]}</DropdownItem>
            <DropdownItem onClick={() => setSort(Sort.ALPHA_UP)}>{texts[Sort.ALPHA_UP]}</DropdownItem>
            <DropdownItem onClick={() => setSort(Sort.ALPHA_DOWN)}>{texts[Sort.ALPHA_DOWN]}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <SearchResultList sort={sort} query={searchText} />
    </div>
  );
};
