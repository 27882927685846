import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Article, TagValues } from '../../../Types';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { LayersControl, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { DivIcon, divIcon, Icon, LatLngExpression, Map } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { printDate } from '../../ArticleInfo/ArticleInfo';
import { Chip } from '../Settings/TagsManager';

import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useTagValues } from '../../ContextProviders/AppContext';
import { useHistory } from 'react-router-dom';

import 'leaflet/dist/leaflet.css';
import './MapComponent.scss';

const { Overlay } = LayersControl;

interface MapComponentProps {
  articles: Article[];
}

interface PopupWindowProps {
  article: Article;
  // eslint-disable-next-line
  style?: any;
}

const createColoredIcon = (color: string) => {
  return new Icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="${color}">
        <path d="M12 2C8.13 2 5 5.13 5 9c0 2.39 1.43 4.71 4.15 7.78l2.85 3.32 2.85-3.32C17.57 13.71 19 11.39 19 9c0-3.87-3.13-7-7-7zm0 10.5c-1.93 0-3.5-1.57-3.5-3.5S10.07 5.5 12 5.5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/>
      </svg>
    `)}`,
    iconSize: [30, 30],
    color: color,
  });
};

const iconCreateFunction = (cluster): DivIcon => {
  const markers = cluster.getAllChildMarkers();
  const colors = markers.map((marker) => marker.options.icon.options.color);

  const colorCount: Record<string, number> = colors.reduce((acc: Record<string, number>, color: string) => {
    acc[color] = (acc[color] || 0) + 1;
    return acc;
  }, {});

  const mostCommonColor = Object.keys(colorCount).reduce((a, b) => (colorCount[a] > colorCount[b] ? a : b));

  return divIcon({
    html: `<div style="background-color:${mostCommonColor};"><span style="color: white; font-size: 12px; text-align: center;">${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster',
    iconSize: [40, 40],
  });
};

export const PopupWindow = ({ article, style }: PopupWindowProps) => {
  const localization = useLocalization();
  const history = useHistory();
  const tagValuesDocs = useTagValues();

  const [currentTags, setCurrentTags] = useState<Record<string, string[]> | undefined>(article?.tags);
  const [values, setValues] = useState<TagValues[]>([]);

  useEffect(() => {
    setValues(tagValuesDocs.docs);
    if (article?.tags) setCurrentTags(article.tags);
  }, [tagValuesDocs, article]);

  return (
    <Card body style={{ padding: 10, border: 'none', ...style }}>
      <CardTitle tag={'h6'}>{article.name}</CardTitle>
      <CardBody>
        <div style={{ display: 'flex' }}>
          <span className="mt-2">
            <strong>{localization.strings.article.articleInfo.tags}:</strong>{' '}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              alignItems: 'center',
            }}
          >
            {currentTags &&
              Object.values(currentTags).map((v) =>
                v.map((value, index) => {
                  const matchingTag = values.find((el) => el.fId === value);
                  return matchingTag ? <Chip key={index} name={matchingTag.tagName} /> : null;
                }),
              )}
          </div>
        </div>
        <div className="my-2">
          <span>
            <strong>{localization.strings.article.articleInfo.dateOfSigning}:</strong> {printDate(article)}
          </span>
        </div>

        <Button size="sm" color="primary" onClick={() => history.push(`/category/${article.category}/${article.fId}`)}>
          {localization.strings.article.articleInfo.visitArticle}
        </Button>
      </CardBody>
    </Card>
  );
};

const LayerControls: FunctionComponent<{}> = () => {
  return (
    <LayersControl position="topleft">
      <Overlay name={'Map Labels'}>
        <TileLayer
          attribution="Esri"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
        />
      </Overlay>
    </LayersControl>
  );
};

const MapComponent: FunctionComponent<MapComponentProps> = ({ articles }: MapComponentProps) => {
  const tagValuesDocs = useTagValues();
  const [values, setValues] = useState<TagValues[]>([]);
  const [articlesWithTags, setArticlesWithTags] = useState<boolean>(true);

  const mapRef = useRef<Map | null>(null);

  const handleMarkerClick = (position: LatLngExpression) => {
    if (mapRef.current) {
      mapRef.current.flyTo(position);
    }
  };

  useEffect(() => {
    setValues(tagValuesDocs.docs);
  }, [tagValuesDocs]);

  useEffect(() => {
    setArticlesWithTags(articles.some((a) => a.tags && Object.keys(a.tags).length > 0));
  }, [articles]);

  const renderMarkes = (noColor: boolean) => {
    return (
      <MarkerClusterGroup chunkedLoading maxClusterRadius={50} iconCreateFunction={iconCreateFunction}>
        {articles.map((article) => {
          let color: string | undefined = '';
          !noColor
            ? (color =
                article.tags &&
                Object.values(article.tags)
                  .flat()
                  .map((tagValue) => values.find((value) => value.fId === tagValue)?.color)
                  .find(Boolean))
            : (color = 'black');

          return (
            <Marker
              position={[Number(article.location?.latitude), Number(article.location?.longitude)]}
              key={article.fId}
              icon={color && color.length > 0 ? createColoredIcon(color) : createColoredIcon('black')}
              eventHandlers={{
                click: () =>
                  handleMarkerClick([Number(article.location?.latitude), Number(article.location?.longitude)]),
              }}
            >
              <Popup maxWidth={500} minWidth={500}>
                <PopupWindow article={article} />
              </Popup>
            </Marker>
          );
        })}
      </MarkerClusterGroup>
    );
  };

  return (
    <>
      <MapContainer
        center={[48.49442498730603, 19.66137923370614]}
        zoom={3}
        scrollWheelZoom={true}
        minZoom={3}
        maxZoom={9.5}
        style={{ height: 500, width: '100%', margin: '50px auto' }}
        dragging={true}
        ref={(map) => (mapRef.current = map)}
        worldCopyJump={true}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
      >
        <TileLayer
          attribution="Esri"
          className="basemap"
          subdomains={['clarity']}
          noWrap={true}
          url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
        <LayerControls />
        {articlesWithTags ? values.length > 0 && renderMarkes(false) : renderMarkes(true)}
      </MapContainer>
    </>
  );
};

export default MapComponent;
