import { ReactElement, useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import './Header.scss';
import {
  SubprojectContext,
  useArticle,
  useArticles,
  useArticlesChangelog,
  useArticlesDraft,
  useCategories,
  useChiefArticles,
  useFullscreen,
  useIsDraft,
  usePublishState,
} from '../ContextProviders/AppContext';

import { useAuth } from '../ContextProviders/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faUpload,
  faCog,
  faSearch,
  faCircleQuestion,
  faRightFromBracket,
  faRightToBracket,
  faPenToSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject, useProjectTitle } from '../ContextProviders/ProjectContext';
import { PublishResult, Article, CMSUser } from '../../Types';
import {
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import { Modal, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { toasts } from '../../shared';
import { ConsistencyReportData, PublishState } from '@eir/core';
import CategoryNavigation from './CategoryNavigation';
import { useSearch } from '../ContextProviders/SearchContext';
import { getByFId } from '../../util/DataHelpers';
import { useOnline } from '../../Hooks/useOnline';
import { HamburgerMenu } from './HamburgerMenu';
import { DarkButton, IconButton } from '../Buttons/Buttons';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import { useFirestore } from '../ContextProviders/Firebase';
import { DocumentData, doc, onSnapshot } from 'firebase/firestore';
import { useDocument } from '../../Hooks';

const AppBranding = (): ReactElement => {
  const project = useConcreteProject();
  const title = useProjectTitle();
  const firestore = useFirestore();
  const [logoUrl, setLogoUrl] = useState('');

  const activeSubproject = useContext(SubprojectContext);
  if (activeSubproject.id !== null) project.id = activeSubproject.id;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(firestore, 'project', project.id), (doc: DocumentData) => {
      doc.data().logo === undefined ? setLogoUrl('/assets/images/active/shared/logo.png') : setLogoUrl(doc.data().logo);
    });

    return () => {
      unsubscribe();
    };
  }, [project.id, firestore]);

  return (
    <Link to="/" className="left-container">
      <img style={{ height: 40 }} src={logoUrl} alt={`${project.id} logo`} width="auto" />
      <h1>{title}</h1>
    </Link>
  );
};

const SEARCH_RESULTS_MAX = 5;
export const SearchInput = () => {
  const localization = useLocalization();
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openDelayed, setOpenDelayed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1); //-1 = none active
  const searchResults = useSearch(searchText).slice(0, SEARCH_RESULTS_MAX);

  const articles = useArticles();
  const chiefArticles = useChiefArticles();
  const changelogArticles = useArticlesChangelog();

  const timeout = useRef(0);
  const history = useHistory();

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setOpenDelayed(isOpen);
    }, 500) as unknown as number;
    if (!isOpen)
      setTimeout(() => {
        setSearchText('');
      }, 500);
  }, [isOpen]);

  const navCapturer: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowUp':
        setSelectedIndex((i) => Math.max(((i + 1 - 1) % (searchResults.length + 2)) - 1, -1)); //Basically, shift up such that -1 index is 0, use modular arithmetic to make it wrap @ len + 2, shift back such that -1 is still -1
        setIsOpen(true);
        e.preventDefault();
        break;
      case 'ArrowDown':
        setSelectedIndex((i) => ((i + 1 + 1) % (searchResults.length + 2)) - 1);
        setIsOpen(true);
        e.preventDefault();
        break;
      case 'Enter':
        if (searchText !== '') {
          if (selectedIndex === -1 || selectedIndex === searchResults.length) {
            history.push(`/search/${searchText}`);
          } else {
            const selected = getByFId(searchResults[selectedIndex].ref || '', [
              ...articles.docs,
              ...chiefArticles.docs,
              ...changelogArticles.docs,
            ]);
            if (!selected) return;

            history.push(`/category/${selected.category}/${selected.fId}`);
          }
          setIsOpen(false);
        } else {
          toasts.error(localization.strings.search.searchError);
        }
        break;
      default:
        // just typing
        setIsOpen(true);
        break;
    }
  };

  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchText]);

  return (
    <Dropdown
      toggle={() => {
        /**/
      }}
      isOpen={openDelayed && searchResults.length > 0}
      className="search-dropdown"
    >
      <DropdownToggle data-toggle="dropdown" tag="div" className="search-input">
        <div
          className={`search-input-parent-stretcher ${isOpen ? 'focused-child' : ''}`}
          style={{ position: 'relative' }}
        >
          <span className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <Input
            style={{ paddingLeft: '2rem' }}
            onFocus={() => {
              setIsOpen(true);
            }}
            onBlurCapture={() => {
              setIsOpen(false);
            }}
            onChange={({ target: { value } }) => setSearchText(value)}
            onKeyDownCapture={navCapturer}
            value={searchText}
            placeholder={localization.strings.global.search}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu style={{ opacity: isOpen ? '1' : '0' }}>
        <DropdownItem header style={{ paddingBottom: '15px', fontWeight: 'bold' }}>
          {localization.strings.search.searchSuggest}
        </DropdownItem>
        {searchResults.map((r, i) => (
          <DropdownItem
            className="fast-search-result d-flex justify-content-between text-wrap"
            key={i}
            active={selectedIndex === i}
            onMouseEnter={() => setSelectedIndex(i)}
            tag="div"
          >
            <InlineSearchResult key={r.ref} id={r.ref} />
          </DropdownItem>
        ))}

        <DropdownItem
          active={selectedIndex === searchResults.length}
          tag="div"
          onMouseEnter={() => setSelectedIndex(searchResults.length)}
        >
          <Link style={{ display: 'block' }} to={`/search/${searchText}`}>
            <FontAwesomeIcon icon={faSearch} /> {localization.strings.search.seeAll} "{searchText}"
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const AdvancedSearchButton = () => {
  const history = useHistory();
  const localization = useLocalization();

  return (
    <IconButton
      theme="light"
      onClick={() => {
        history.push('/advanced');
      }}
      text={localization.strings.search.advancedSearch}
      icon={faSearch}
      style={{
        maxWidth: 200,
        width: '100%',
        whiteSpace: 'nowrap',
      }}
    ></IconButton>
  );
};

const InlineSearchResult = ({ id }: { id: string }) => {
  const localization = useLocalization();
  const article = useArticle(id);

  if (!article) {
    return <>{localization.strings.error.error}</>;
  }

  return (
    <Link style={{ display: 'block', width: '100%' }} to={`/category/${article.category}/${article.fId}`}>
      {article.name}
    </Link>
  );
};

export const DraftModeSwitcher = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const [isDraft, setIsDraft] = useIsDraft();
  const online = useOnline();
  const { pathname } = useLocation();
  const history = useHistory();
  if (pathname === '/publish' && !isDraft) {
    history.push('/');
  }

  useEffect(() => {
    if (!online) setIsDraft(false);
    // eslint-disable-next-line
  }, [online]);

  if (!auth.isAdmin || !online) return <></>;
  return (
    <IconButton
      onClick={() => setIsDraft(!isDraft)}
      style={{ whiteSpace: 'nowrap' }}
      icon={isDraft ? faEye : faPenToSquare}
      text={isDraft ? localization.strings.adminViewMode.enterPublishMode : localization.strings.global.edit}
      theme="light"
    />
  );
};

interface ConsistencyReportProps {
  onDone: () => void;
  result: PublishResult;
  isOpen: boolean;
}

const ConsistencyReportModal = ({ result, onDone, isOpen }: ConsistencyReportProps): ReactElement => {
  const localization = useLocalization();
  const articles = useArticlesDraft();
  const categories = useCategories();

  const categoryIdNameMap: Record<string, string> = categories.docs.reduce((acc, cat) => {
    acc[cat.fId] = cat.name;
    return acc;
  }, {});
  const articlesById: Record<string, Article> = articles.docs.reduce((acc, a) => {
    acc[a.fId] = a;
    return acc;
  }, {});

  return (
    <Modal isOpen={isOpen} zIndex={99999}>
      <ModalHeader>
        <h3>{localization.strings.publish.publishFail}</h3>
      </ModalHeader>
      <ModalBody>
        <ul style={{ listStyle: 'none' }}>
          {Object.entries(result.consistencyReport as { [key: string]: ConsistencyReportData }).map(
            ([articleId, reportData]) => {
              const article = articlesById[articleId];
              const categoryName = categoryIdNameMap[article.category];

              return (
                <li key={articleId} style={{ paddingBottom: '1rem' }}>
                  <h5>
                    <Link
                      to={`/category/${article.category}/${articleId}`}
                      onClick={() => {
                        onDone();
                      }}
                    >
                      {article.name} ({categoryName})
                    </Link>
                  </h5>
                  {reportData.href.length > 0 && (
                    <>
                      <h6>{localization.strings.publish.linksWithIssues}</h6>
                      <ul>
                        {reportData.href.map((link) => (
                          <li key={link} style={{ wordBreak: 'break-all' }}>
                            {link}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {reportData.images.length > 0 && (
                    <>
                      <h6>{localization.strings.publish.imagesWithIssues}</h6>
                      <ul>
                        {reportData.images.map((image) => (
                          <li key={image} style={{ wordBreak: 'break-all' }}>
                            {image}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              );
            },
          )}
        </ul>
      </ModalBody>
      <ModalFooter>
        <DarkButton
          color="primary"
          onClick={() => {
            onDone();
          }}
        >
          {localization.strings.global.ok}
        </DarkButton>
      </ModalFooter>
    </Modal>
  );
};

export const UserNav = (): ReactElement => {
  const localization = useLocalization();
  const { user, signOut } = useAuth();
  const history = useHistory();

  if (!user) {
    return (
      <IconButton
        onClick={() => history.push('/sign-in')}
        style={{ whiteSpace: 'nowrap' }}
        icon={faRightToBracket}
        text={localization.strings.auth.signInButton}
        theme="light"
      />
    );
  } else {
    return (
      <IconButton
        onClick={signOut}
        style={{ whiteSpace: 'nowrap' }}
        icon={faRightFromBracket}
        text={localization.strings.auth.signOutButton}
        theme="light"
        tooltip={user.displayName || undefined}
      />
    );
  }
};

export const RegisterButton = (): ReactElement => {
  const localization = useLocalization();
  const { user } = useAuth();
  const history = useHistory();
  const config = useConcreteProject();

  if (user || !config.uniformAuthentication || !config.allowAccountCreation) return <></>;

  return (
    <IconButton
      onClick={() => history.push('/register')}
      style={{ whiteSpace: 'nowrap' }}
      icon={faRightToBracket}
      text={localization.strings.auth.registerButton}
      theme="light"
    />
  );
};

export const SettingsButton = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const history = useHistory();
  const project = useConcreteProject();

  if (project.uniformAuthentication && project.allowAccountCreation ? !auth.user : !auth.isAdmin) return <></>;

  return (
    <IconButton
      onClick={() => {
        history.push('/settings');
      }}
      style={{ whiteSpace: 'nowrap' }}
      icon={faCog}
      text={localization.strings.settings.settings}
      theme="light"
    />
  );
};

export const ChangePassword = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const { doc } = useDocument<CMSUser>(`cmsUser/${auth.user?.uid}`, {});
  const { accessChangePass } = useAuth();
  const history = useHistory();
  const project = useConcreteProject();

  if (
    auth.user &&
    auth.isStaff &&
    !doc.isGroupAccount &&
    ((project.uniformAuthentication && !project.allowAccountCreation) || accessChangePass === 'true')
  )
    return (
      <IconButton
        onClick={() => {
          history.push('/change-password');
        }}
        style={{ whiteSpace: 'nowrap' }}
        icon={faCog}
        text={localization.strings.settings.settings}
        theme="light"
      />
    );
  return <></>;
};

export const AdminModeBanner = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const [draftMode] = useIsDraft();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <div className={`admin-banner ${auth.isAdmin ? 'admin-mode' : ''} ${draftMode ? 'draft-mode' : ''}`}>
      <strong>
        {global.youAreIn} {draftMode ? localization.strings.publish.editMode : localization.strings.publish.publishMode}
      </strong>
      <IconButton
        type="button"
        className="ml-4"
        id="bannerHelpButton"
        icon={faCircleQuestion}
        style={{
          padding: 'unset',
          background: 'transparent',
          width: '1.5rem',
          borderRadius: '50%',
          color: 'white',
          marginTop: '0.1rem',
        }}
        onClick={toggle}
      />
      <UncontrolledPopover placement="bottom" target="bannerHelpButton" toggle={toggle} isOpen={popoverOpen}>
        {draftMode ? (
          <>
            <PopoverHeader>{localization.strings.publish.editMode}</PopoverHeader>
            <PopoverBody>{localization.strings.adminViewMode.editTip}</PopoverBody>
          </>
        ) : (
          <>
            <PopoverHeader>{localization.strings.publish.publishMode}</PopoverHeader>
            <PopoverBody>{localization.strings.adminViewMode.publishTip}</PopoverBody>
          </>
        )}
      </UncontrolledPopover>
    </div>
  );
};
export const PublishButton = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const history = useHistory();
  const [draftMode] = useIsDraft();
  const [publishState, setPublishState, publishResult, setPublishResult] = usePublishState();
  const { pathname } = useLocation();

  if (!auth.isAdmin || !draftMode) return <></>;

  return (
    <>
      <IconButton
        onClick={() => {
          history.push('/publish');
        }}
        style={{ whiteSpace: 'nowrap', display: pathname === '/publish' ? 'none' : 'unset' }}
        icon={faUpload}
        text={
          publishState === PublishState.IN_FLIGHT
            ? localization.strings.publish.publishing
            : localization.strings.publish.publish
        }
        isLoading={publishState === PublishState.IN_FLIGHT}
        useCustomSpinner
        theme="light"
      />
      {publishResult && (
        <ConsistencyReportModal
          isOpen={true}
          result={publishResult}
          onDone={() => {
            setPublishState(PublishState.IDLE);
            setPublishResult(null);
          }}
        />
      )}
    </>
  );
};

export const Header = (): ReactElement => {
  const [fs] = useFullscreen();
  const auth = useAuth();
  const project = useConcreteProject();

  return (
    <>
      <AdminModeBanner />
      <header className={`${fs && 'fullscreen'}${auth.isAdmin && ' admin-mode'}`}>
        <div className="content">
          {!fs && (
            <div className="header-top-row">
              <AppBranding />
              <div className="right-container">
                <SearchInput />
                {project.enableTagSearch && <AdvancedSearchButton />}
                <div className="header-top-button">
                  <SettingsButton />
                  <ChangePassword />
                  <PublishButton />
                  <DraftModeSwitcher />
                  <UserNav />
                  <RegisterButton />
                </div>
              </div>
              <HamburgerMenu />
            </div>
          )}
          <CategoryNavigation isMiniMenu={true} />
          <div className="header-top-category-navigation">
            <CategoryNavigation isMiniMenu={false} />
          </div>
        </div>
      </header>
    </>
  );
};
