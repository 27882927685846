import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { SubprojectContext, useProjectConfig } from '../../ContextProviders/AppContext';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { IconButton } from '../../Buttons/Buttons';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import confirm from 'reactstrap-confirm';
import {
  faCirclePlus,
  faMagnifyingGlass,
  faArrowUpRightFromSquare,
  faText,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { doc, getDoc, setDoc, collection, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useFirestore, useRecursiveProjectDeletion, useStorage } from '../../ContextProviders/Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import { WithID } from '../../../Types';
import { useCollection } from '../../../Hooks';
import { useStyle } from '../../ContextProviders/Style';
import './SubprojectsPage.scss';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export interface Subproject {
  index: number;
  fId: string;
  name: string;
  logo: File | null;
  creationDate: Timestamp;
  lastUpdated: Timestamp;
  admin: string;
  style: Style;
  mainProject: boolean | null;
  organization: string;
  publisher: string;
  allowedUsers?: number;
  categoryEditPermissions: string[];
}

export interface Style {
  primary: string;
  primaryLight: string;
  primaryDark: string;
  accent: string;
}

interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

type Order = 'asc' | 'desc';
type Attribute = 'index' | 'name' | 'logo' | 'allowedUsers' | 'creationDate' | 'lastUpdate' | 'admin';
type SortOptions = {
  order: Order;
  attribute: Attribute;
};

type SortButtonProps = {
  attribute: Attribute;
  disabled: boolean;
  sortOptions: SortOptions;
  handleClick: (attribute: Attribute) => void;
};

const SortButton = ({ attribute, disabled, sortOptions, handleClick }: SortButtonProps): ReactElement => {
  const localization = useLocalization();
  const { sorting } = localization.strings.settings.subprojects;
  const title = sorting[attribute];

  return (
    <Col onClick={() => handleClick(attribute)} className="sort-button" disabled={disabled}>
      {title}
      {!disabled && (
        <FontAwesomeIcon
          icon={sortOptions.attribute === attribute && sortOptions.order === 'desc' ? faAngleUp : faAngleDown}
          className={` mx-1 sort-indicator ${sortOptions.attribute === attribute && 'active'}`}
        ></FontAwesomeIcon>
      )}
    </Col>
  );
};

export const SubprojectsManager = (): ReactElement => {
  const localization = useLocalization();
  const projectConfig = useProjectConfig();
  const project = useConcreteProject();
  const firestore = useFirestore();
  const deleteProjectRecursively = useRecursiveProjectDeletion();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState('');
  const [subprojects, setSubprojects] = useState<Subproject[]>([]);
  const [filter, setFilter] = useState<string | null>(null);
  const [sortOptions, setSortOptions] = useState<SortOptions>({ attribute: 'index', order: 'asc' });
  const projects = useCollection<Subproject & WithID>(`project`, {});
  const [modalOpen, setModalOpen] = useState(false);
  const [cacheProject, setCacheProject] = useState<Subproject | null>(null);
  const [mainProject, setMainProject] = useState<Subproject | null>(null);
  const [invalidModal, setInvalidModal] = useState<boolean[]>([false, false, false, false, false, false, false, false]);
  const style = useStyle();
  const title = useProjectTitle();

  const resetNewProject = () => {
    return {
      index: 0,
      fId: '',
      name: '',
      logo: null,
      creationDate: {
        seconds: 0,
        nanoseconds: 0,
      },
      lastUpdated: {
        seconds: 0,
        nanoseconds: 0,
      },
      admin: '',
      style: style,
      mainProject: false,
      publisher: '',
      organization: '',
      categoryEditPermissions: [],
    };
  };

  const [newProject, setNewProject] = useState<Subproject>(resetNewProject());
  const activeSubproject = useContext(SubprojectContext);
  const storage = useStorage();

  useEffect(() => {
    checkModalValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProject]);

  useEffect(() => {
    const getMainProject = () => {
      if (mainProject !== null || style === null) return;

      // Check using mainProject boolean in project
      for (const d of projects.docs) {
        if (d.mainProject === true) {
          const proj: Subproject = d;
          proj.index = 0;
          proj.style = style;
          setMainProject(proj);
          return;
        }
      }

      // Check using projectConfig name
      for (const d of projects.docs) {
        if (d.name === projectConfig.doc.name) {
          const proj: Subproject = d;
          proj.index = 0;
          proj.style = style;
          setMainProject(proj);
          return;
        }
      }
    };

    const getSubprojects = () => {
      if (projects.docs.length === 0 || mainProject === null) return;

      const arr: Subproject[] = [];
      if (filter === null || mainProject.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        arr.push(mainProject);

      for (const d of projects.docs) {
        if (d.fId === mainProject.fId) continue;
        const proj: Subproject = d;
        proj.index = arr.length;
        if (filter === null || proj.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) arr.push(proj);
      }

      const { attribute, order } = sortOptions;
      const sortedData = [...arr].sort((a, b) => {
        const isNumeric = attribute === 'index';
        if (!isNumeric) {
          const valueA = (a[attribute] || '').toString();
          const valueB = (b[attribute] || '').toString();
          return valueA.localeCompare(valueB, { numeric: true }) * (order === 'asc' ? 1 : -1);
        } else {
          const valueA = a[attribute];
          const valueB = b[attribute];
          return (valueA > valueB ? 1 : -1) * (order === 'asc' ? 1 : -1);
        }
      });
      setSubprojects([...sortedData]);
    };
    if (projects && projects.docs.length > 0) {
      getMainProject();
      getSubprojects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.docs]);

  useEffect(() => {
    document.title = `${localization.strings.settings.subprojects.title} | ${title}`;
  }, [title, localization.strings.settings.subprojects.title]);

  const copyDocuments = async (original: string, copy: string, title: string) => {
    const docs = await getDocs(collection(firestore, `project/${original}/${title}`));
    for (const d of docs.docs) await setDoc(doc(firestore, `project/${copy}/${title}/${d.id}`), d.data());
  };

  const copyCategoryDraftDocuments = async (original: string, copy: string, title: string) => {
    const docs = await getDocs(collection(firestore, `project/${original}/${title}`));
    for (const d of docs.docs) {
      const newDoc = {
        ...d.data(),
        siblingDocReference: doc(firestore, `project/${copy}/category/${d.id}`),
      };
      await setDoc(doc(firestore, `project/${copy}/${title}/${d.id}`), newDoc);
    }
  };

  const copyAllDocuments = async (original: string, copy: string) => {
    await Promise.all([
      copyDocuments(original, copy, 'category'),
      copyCategoryDraftDocuments(original, copy, 'categoryDraft'),
      copyDocuments(original, copy, 'configuration'),
      copyDocuments(original, copy, 'resources'),
      copyDocuments(original, copy, 'aggregatedHelpers'),
    ]);
  };

  const addProject = async () => {
    if (mainProject === null) return;
    if (newProject.fId === mainProject.fId || newProject.fId === project.id) return;
    setIsRunning(true);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    let link: any = newProject.logo;
    let logoPathRef;

    try {
      if (newProject.logo?.name) {
        logoPathRef = ref(
          storage,
          `/public/${newProject.fId}/assets/logo/logo.${getFileExtension(newProject.logo?.name)}`,
        );
        await uploadBytes(logoPathRef, newProject.logo, { customMetadata: { logoId: newProject.fId } });
        link = await getDownloadURL(logoPathRef);
      }

      if ((await getDoc(doc(firestore, `project/${newProject.fId}`))).exists()) {
        console.log('Subproject already exist');
        return;
      }

      const docSnap = await getDoc(doc(firestore, `project/${mainProject.fId}`));
      if (!docSnap.exists()) {
        console.log('Main project does not exist');
        return;
      }

      await setDoc(doc(firestore, `project/${newProject.fId}`), docSnap.data());
      await copyAllDocuments(mainProject.fId, newProject.fId);
      const __allowedUsers = project.firebase.project === 'infosynk-sameffekt' ? -1 : 0;
      await updateDoc(doc(firestore, `project/${newProject.fId}`), {
        name: newProject.name,
        logo: link,
        creationDate: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        admin: newProject.admin,
        allowedUsers: __allowedUsers,
        style: newProject.style,
        mainProject: false,
        organization: newProject.organization,
        publisher: newProject.publisher,
      });
      await updateDoc(doc(firestore, `project/${newProject.fId}/configuration/draft`), {
        name: newProject.name,
        title: newProject.name,
        lastUpdated: serverTimestamp(),
        organization: newProject.organization,
        publisher: newProject.publisher,
        allowedUsers: __allowedUsers,
      });
      await updateDoc(doc(firestore, `project/${newProject.fId}/configuration/published`), {
        name: newProject.name,
        title: newProject.name,
        lastUpdated: serverTimestamp(),
        organization: newProject.organization,
        publisher: newProject.publisher,
        allowedUsers: __allowedUsers,
      });
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsRunning(false);
    }
  };

  const searchProjects = () => {
    setIsRunning(true);
    if (filterValue.length === 0) setFilter(null);
    else setFilter(filterValue);
    setIsRunning(false);
  };

  const keyboardSearchProjects = (event: { key: string }) => {
    if (event.key === 'Enter') searchProjects();
  };

  const chooseProject = (item: Subproject) => {
    if (mainProject === null) return;
    setIsRunning(true);
    try {
      if (item.fId === mainProject.fId) activeSubproject.clear();
      else activeSubproject.set(item.fId, item.name, item.style);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsRunning(false);
    }
  };

  const getFileExtension = (name: string): string => {
    return name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2);
  };

  const updateProject = async () => {
    if (mainProject === null) return;
    setIsRunning(true);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    let link: any = newProject.logo;
    let logoPathRef;

    try {
      if (newProject.logo?.name) {
        logoPathRef = ref(
          storage,
          `/public/${newProject.fId}/assets/logo/logo.${getFileExtension(newProject.logo?.name)}`,
        );
        await uploadBytes(logoPathRef, newProject.logo, { customMetadata: { logoId: newProject.fId } });
        link = await getDownloadURL(logoPathRef);
      }
      if (newProject.fId === mainProject.fId) {
        await updateDoc(doc(firestore, `project/${newProject.fId}`), {
          name: newProject.name,
          lastUpdated: serverTimestamp(),
          style: newProject.style,
          organization: newProject.organization,
          publisher: newProject.publisher,
          title: newProject.name,
        });
      } else {
        await updateDoc(doc(firestore, `project/${newProject.fId}`), {
          name: newProject.name,
          logo: link,
          lastUpdated: serverTimestamp(),
          admin: newProject.admin,
          style: newProject.style,
          organization: newProject.organization,
          publisher: newProject.publisher,
          title: newProject.name,
        });
      }
      await updateDoc(doc(firestore, `project/${newProject.fId}/configuration/draft`), {
        name: newProject.name,
        organization: newProject.organization,
        publisher: newProject.publisher,
        title: newProject.name,
        lastUpdated: serverTimestamp(),
      });
      await updateDoc(doc(firestore, `project/${newProject.fId}/configuration/published`), {
        name: newProject.name,
        organization: newProject.organization,
        publisher: newProject.publisher,
        title: newProject.name,
        lastUpdated: serverTimestamp(),
      });
    } catch (exception) {
      console.log(exception);
    } finally {
      if (newProject.fId === mainProject.fId || newProject.fId === project.id) {
        activeSubproject.set(newProject.fId, newProject.name, newProject.style);
        activeSubproject.setLogoUrl(link);
        activeSubproject.setReload(true);
      }

      setIsRunning(false);
    }
  };

  const deleteProject = async (item: Subproject) => {
    if (mainProject === null) return;
    if (item.fId === mainProject.fId || item.fId === project.id) return;
    const result = await confirm({
      title: `${localization.strings.global.delete} ${localization.strings.settings.subprojects.placeholder}`,
      message: `${localization.strings.settings.subprojects.delete} ${item.name}?`,
      confirmText: localization.strings.global.confirm,
      confirmColor: 'primary',
      cancelText: localization.strings.global.cancel,
      cancelColor: 'link text-danger',
    });
    if (!result) return;

    setIsRunning(true);

    try {
      const path = `project/${item.fId}`;
      const result = await deleteProjectRecursively({ path, projectId: item.fId });
      if (result.data.success) {
        console.log('Document and its subcollections deleted successfully.');
      } else {
        console.error('Error deleting document:', result.data.error);
      }
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsRunning(false);
    }
  };

  const checkModalValues = () => {
    setIsDisable(
      !isValidId(newProject.fId) ||
        !isValidName(newProject.name) ||
        newProject.logo === null ||
        newProject.admin === '' ||
        !isHex(newProject.style.primary) ||
        !isHex(newProject.style.primaryLight) ||
        !isHex(newProject.style.primaryDark) ||
        !isHex(newProject.style.accent),
    );
  };

  const isValidId = (id: string) => {
    if (cacheProject !== null) return true;
    if (subprojects.find((i) => i.fId === id)) return false;
    if (id === '') return false;
    return new RegExp('^[a-z0-9_]*$').test(id);
  };

  const isValidName = (name: string) => {
    if (cacheProject === null && subprojects.find((i) => i.name === name)) return false;
    if (name === '') return false;
    return new RegExp('^[a-zA-ZäöåÄÖÅ0-9_ \\-:;&,.]*$').test(name);
  };

  const isHex = (text: string) => {
    return new RegExp('^#([0-9A-Fa-f]{3,6})$').test(text);
  };

  const onFilterValueChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    setFilterValue(e.target.value);
  };

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  const handleSortClick = (attribute: Attribute) => {
    if (sortOptions.attribute === attribute) {
      setSortOptions({
        attribute,
        order: sortOptions.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortOptions({ attribute, order: 'asc' });
    }
  };

  const RenderList = () => {
    return (
      <div>
        <RenderListHeader />
        <RenderListBody />
      </div>
    );
  };

  const RenderListHeader = () => {
    return (
      <Row className="mb-2 align-items-center">
        <Col className="col-sm">
          <span>
            <SortButton
              disabled={subprojects.length === 0}
              attribute="index"
              sortOptions={sortOptions}
              handleClick={handleSortClick}
            />
          </span>
        </Col>

        <Col>
          <SortButton
            disabled={subprojects.length === 0}
            attribute="name"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>

        <Col>
          <SortButton
            disabled={true}
            attribute="logo"
            sortOptions={sortOptions}
            handleClick={() => {
              /* */
            }}
          />
        </Col>

        <Col>
          <SortButton
            disabled={true}
            attribute="allowedUsers"
            sortOptions={sortOptions}
            handleClick={() => {
              /* */
            }}
          />
        </Col>

        <Col>
          <SortButton
            disabled={subprojects.length === 0}
            attribute="creationDate"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>

        <Col>
          <SortButton
            disabled={subprojects.length === 0}
            attribute="lastUpdate"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>

        <Col>
          <SortButton
            disabled={subprojects.length === 0}
            attribute="admin"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>

        <Col></Col>
      </Row>
    );
  };

  const RenderListBody = () => {
    if (mainProject === null) return <></>;

    return (
      <div className={`${isRunning ? 'text-muted' : ''} align-items-center`}>
        {subprojects.map((item: Subproject, index) => (
          <Row key={`project-${index}`} className={`${project.id === item.fId ? 'activeProjectRow' : ''}`}>
            <Col className="text">{(item.index + 1).toString()}</Col>

            <Col className="text">{item.name}</Col>
            <Col style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
              {String(item.logo) !== '' && String(item.logo) !== 'x' ? (
                <img
                  className="tableLogoItem"
                  src={item.mainProject ? '/assets/images/active/shared/logo.png' : String(item.logo)}
                  alt={`Logo ${item.name}`}
                  style={{ maxWidth: '120px', maxHeight: '40px', height: 'auto' }}
                />
              ) : (
                <></>
              )}
            </Col>
            <Col className="text">
              {item.allowedUsers === -1 ? <span style={{ fontSize: 22 }}>∞</span> : item.allowedUsers}
            </Col>
            <Col className="text">{presentTime(item.creationDate)}</Col>
            <Col className="text">{presentTime(item.lastUpdated)}</Col>
            <Col className="text">{item.admin}</Col>

            <Col className="col text-end buttons">
              <Button
                color="success"
                style={{ borderRadius: '0.4rem' }}
                outline={true}
                onClick={() => chooseProject(item)}
                disabled={isRunning || item.fId === project.id}
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare}></FontAwesomeIcon>
              </Button>

              {item.fId !== mainProject.fId || item.fId === mainProject.fId ? (
                <Button
                  color="warning"
                  style={{ borderRadius: '0.4rem' }}
                  outline={true}
                  onClick={() => {
                    setCacheProject(item);
                    setNewProject(item);
                    setInvalidModal([false, false, false, false, false, false, false, false, false]);
                    toggleModal(true);
                  }}
                  disabled={false}
                >
                  <FontAwesomeIcon icon={faText}></FontAwesomeIcon>
                </Button>
              ) : (
                <></>
              )}

              {item.fId !== mainProject.fId ? (
                <Button
                  color="danger"
                  style={{ borderRadius: '0.4rem' }}
                  outline={true}
                  onClick={() => void deleteProject(item)}
                  disabled={isRunning || item.fId === project.id}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  const presentTime = (time: Timestamp | null) => {
    if (time === null || time === undefined) return '---';
    if (
      time.seconds == null ||
      time.seconds === undefined ||
      time.nanoseconds == null ||
      time.nanoseconds === undefined
    )
      return 'Error';
    const date = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
    return (
      date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }) +
      ' ' +
      date.toLocaleTimeString('en-GB', { hour12: false })
    );
  };

  const RenderModal = () => {
    return (
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(false)}>
          {cacheProject === null
            ? localization.strings.settings.subprojects.add
            : mainProject?.fId === newProject.fId
            ? localization.strings.settings.projectSettings
            : localization.strings.settings.subprojects.edit}
        </ModalHeader>
        <ModalBody>
          <Form>
            {cacheProject === null ? (
              <div id="modal-id">
                <Label>{localization.strings.settings.subprojects.sorting.id}</Label>
                <Input
                  onChange={(e) => {
                    setNewProject((prevState) => ({
                      ...prevState,
                      fId: e.target.value,
                    }));

                    setInvalidModal(
                      invalidModal.map((elem, index) => {
                        if (index === 0) return !isValidId(e.target.value);
                        return elem;
                      }),
                    );
                  }}
                  value={newProject.fId}
                  invalid={invalidModal[0]}
                ></Input>
                <UncontrolledTooltip placement="right" target="modal-id">
                  <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.id}</p>
                </UncontrolledTooltip>
              </div>
            ) : (
              <></>
            )}

            <div id="modal-name">
              <Label>{localization.strings.settings.subprojects.sorting.name}</Label>
              <Input
                onChange={(e) => {
                  setNewProject((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));

                  setInvalidModal(
                    invalidModal.map((elem, index) => {
                      if (index === 1) return !isValidName(e.target.value);
                      return elem;
                    }),
                  );
                }}
                value={newProject.name}
                invalid={invalidModal[1]}
              ></Input>
              <UncontrolledTooltip placement="right" target="modal-name">
                <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.name}</p>
              </UncontrolledTooltip>
            </div>
            <div id="modal-organization">
              <Label>{localization.strings.settings.projectOrganizationHint}</Label>
              <Input
                type="text"
                value={newProject.organization}
                onChange={(e) => {
                  setNewProject((prevState) => ({ ...prevState, organization: e.target.value }));
                  setInvalidModal(
                    invalidModal.map((element, index) => {
                      if (index === 2) return !isValidName(e.target.value);
                      return element;
                    }),
                  );
                }}
                invalid={invalidModal[2]}
              ></Input>
            </div>
            <div id="modal-publisher">
              <Label>{localization.strings.settings.projectPublisherHint}</Label>
              <Input
                type="text"
                value={newProject.publisher}
                onChange={(e) => {
                  setNewProject((prevState) => ({ ...prevState, publisher: e.target.value }));
                  setInvalidModal(
                    invalidModal.map((element, index) => {
                      if (index === 3) return !isValidName(e.target.value);
                      return element;
                    }),
                  );
                }}
                invalid={invalidModal[3]}
              ></Input>
            </div>
            {newProject.fId !== mainProject?.fId ? (
              <>
                <div id="modal-logo">
                  <Label>{localization.strings.settings.subprojects.sorting.logo}</Label>
                  <Input
                    onChange={(e) => {
                      const logoFile = e.target.files?.[0]; // Use optional chaining to safely access e.target.files[0]
                      if (logoFile) {
                        setNewProject((prevState) => ({
                          ...prevState,
                          logo: logoFile,
                        }));
                      }
                    }}
                    type="file"
                    name="logo-image"
                    id="logo"
                    accept="image/png, image/jpeg, image/jpg"
                  ></Input>
                  <UncontrolledTooltip placement="right" target="modal-logo">
                    <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.logo}</p>
                  </UncontrolledTooltip>
                </div>
                <div id="modal-admin">
                  <Label>{localization.strings.settings.subprojects.sorting.admin}</Label>
                  <Input
                    onChange={(e) => {
                      setNewProject((prevState) => ({
                        ...prevState,
                        admin: e.target.value,
                      }));

                      setInvalidModal(
                        invalidModal.map((elem, index) => {
                          if (index === 4) return e.target.value === '';
                          return elem;
                        }),
                      );
                    }}
                    value={newProject.admin}
                    invalid={invalidModal[4]}
                  ></Input>
                  <UncontrolledTooltip placement="right" target="modal-admin">
                    <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.admin}</p>
                  </UncontrolledTooltip>
                </div>
              </>
            ) : (
              <></>
            )}
            <div id="modal-style-primary">
              <Label>{localization.strings.settings.subprojects.sorting.style.primary}</Label>
              <div className="colors-input-row">
                <Input
                  type="color"
                  style={{ width: 50, height: 35 }}
                  defaultValue={newProject.style.primary}
                  invalid={invalidModal[5]}
                  placeholder="#ffffff"
                  onChange={(e) => {
                    newProject.style.primary = e.target.value;
                    const { name, value } = e.target;
                    setNewProject((prevState) => ({
                      ...prevState,
                      [name]: value.toUpperCase(),
                    }));

                    setInvalidModal(
                      invalidModal.map((elem, index) => {
                        if (index === 5) return !isHex(e.target.value);
                        return elem;
                      }),
                    );
                  }}
                ></Input>
                <p>{newProject.style.primary}</p>
              </div>
              <UncontrolledTooltip placement="right" target="modal-style-primary">
                <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.style.primary}</p>
              </UncontrolledTooltip>
            </div>

            <div id="modal-style-primaryLight">
              <Label>{localization.strings.settings.subprojects.sorting.style.primaryLight}</Label>
              <div className="colors-input-row">
                <Input
                  type="color"
                  style={{ width: 50, height: 35 }}
                  defaultValue={newProject.style.primaryLight}
                  invalid={invalidModal[6]}
                  placeholder="#ffffff"
                  onChange={(e) => {
                    newProject.style.primaryLight = e.target.value;
                    const { name, value } = e.target;
                    setNewProject((prevState) => ({
                      ...prevState,
                      [name]: value.toUpperCase(),
                    }));

                    setInvalidModal(
                      invalidModal.map((elem, index) => {
                        if (index === 6) return !isHex(e.target.value);
                        return elem;
                      }),
                    );
                  }}
                ></Input>
                <p>{newProject.style.primaryLight}</p>
              </div>
              <UncontrolledTooltip placement="right" target="modal-style-primaryLight">
                <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.style.primaryLight}</p>
              </UncontrolledTooltip>
            </div>

            <div id="modal-style-primaryDark">
              <Label>{localization.strings.settings.subprojects.sorting.style.primaryDark}</Label>
              <div className="colors-input-row">
                <Input
                  type="color"
                  style={{ width: 50, height: 35 }}
                  defaultValue={newProject.style.primaryDark}
                  invalid={invalidModal[7]}
                  placeholder="#ffffff"
                  df1b21
                  onChange={(e) => {
                    newProject.style.primaryDark = e.target.value;
                    const { name, value } = e.target;
                    setNewProject((prevState) => ({
                      ...prevState,
                      [name]: value.toUpperCase(),
                    }));

                    setInvalidModal(
                      invalidModal.map((elem, index) => {
                        if (index === 7) return !isHex(e.target.value);
                        return elem;
                      }),
                    );
                  }}
                ></Input>
                <p>{newProject.style.primaryDark}</p>
              </div>
              <UncontrolledTooltip placement="right" target="modal-style-primaryDark">
                <p className="modal-tooltip">{localization.strings.settings.subprojects.tooltips.style.primaryDark}</p>
              </UncontrolledTooltip>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setNewProject(resetNewProject);
              setModalOpen(false);
            }}
          >
            {localization.strings.global.cancel}
          </Button>
          <Button
            color="success"
            disabled={isRunning || isDisable}
            onClick={() => {
              if (cacheProject === null) void addProject();
              else void updateProject();
              toggleModal(false);
            }}
          >
            {cacheProject === null ? localization.strings.global.add : localization.strings.global.save}
          </Button>
          <Button
            color="danger"
            onClick={() => {
              if (cacheProject !== null) setNewProject(cacheProject);
              else setNewProject(resetNewProject);
              setInvalidModal([false, false, false, false, false, false, false, false]);
            }}
          >
            {localization.strings.global.reset}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const toggleModal = (open: boolean) => {
    if (!open) setCacheProject(null);
    setModalOpen(open);
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page subprojects-page">
          <h1>{localization.strings.settings.subprojects.title}</h1>
          <hr />
          <Row>
            <InputGroup style={{ position: 'relative' }}>
              <IconButton
                className="mx-2"
                onClick={() => {
                  setCacheProject(null);
                  setNewProject(resetNewProject);
                  setInvalidModal([false, false, false, false, false, false, false, false]);
                  toggleModal(true);
                }}
                disabled={isRunning}
                style={{ margin: '0 0 0 0.4rem', position: 'relative' }}
                theme="dark"
                icon={faCirclePlus}
                text={localization.strings.global.add}
              />

              <Input
                className="mx-2"
                type="text"
                value={filterValue}
                onChange={onFilterValueChange}
                onKeyDown={keyboardSearchProjects}
                disabled={isRunning}
                placeholder={localization.strings.settings.subprojects.placeholder}
                style={{ maxWidth: '15rem' }}
              />

              <IconButton
                className="mx-2"
                onClick={searchProjects}
                disabled={isRunning}
                style={{ margin: '0 0 0 0.4rem', position: 'relative' }}
                theme="dark"
                icon={faMagnifyingGlass}
                text={localization.strings.global.search}
              />
            </InputGroup>
          </Row>
          <br />
          <div className="subprojects-list shadow-sm">
            {subprojects.length > 0 ? <RenderList /> : localization.strings.settings.subprojects.empty}
          </div>
        </div>
      </main>
      {RenderModal()}
    </PageWithSidebar>
  );
};
