import React, { useEffect, useRef, useState } from 'react';
import { Timeline } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import { DateOfSigning } from '@eir/core';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { printDate } from '../../ArticleInfo/ArticleInfo';
import { PopupWindow } from './MapComponent';
import { Article } from '../../../Types';

type TimelineProps = {
  articles: Article[];
};

const TimelineComponent: React.FC<TimelineProps> = ({ articles }) => {
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const timelineInstanceRef = useRef<Timeline | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const localization = useLocalization();

  const isSafari =
    navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1;

  const articleDate = (dateObj: DateOfSigning | undefined): string | Date => {
    const splitter = isSafari ? '/' : '-';
    const refinedDateObj = [dateObj?.year, dateObj?.month ?? '01', dateObj?.day ?? '01'];
    const finalStartDate = refinedDateObj.join(splitter);

    return isSafari ? new Date(finalStartDate) : finalStartDate;
  };

  const articleForTimetable = articles.map((article) => ({
    id: article.fId,
    name: article.name,
    content: article.name,
    start: articleDate(article.signing?.startDate),
    selectable: true,
    signing: article.signing,
  }));

  useEffect(() => {
    const container = timelineRef.current;

    if (!container) return;

    if (!timelineInstanceRef.current) {
      const options = {
        zoomable: true,
        zoomMin: 1000 * 60 * 60 * 24, // one day
        horizontalScroll: true,
        moveable: true,
        showTooltips: true,
        min: new Date('1400/01/01'),
        max: new Date('2000/01/01'),
        height: 200,
        maxHeight: 200,
        tooltip: {
          template: function (item, editableDate) {
            if (item.items) {
              return item.items.map((i, index: number) => `<span><strong>#${index + 1}: ${i.name}</strong></span><br>`);
            } else {
              return `
              <p><strong>${item.name}<strong></p>
              <p><strong>${localization.strings.article.articleInfo.dateOfSigning}: </strong>${printDate(item)}</p>
              `;
            }
          },
        },
        cluster: { maxItems: 1 },
      };

      timelineInstanceRef.current = new Timeline(container, articleForTimetable, options);

      timelineInstanceRef.current.on('click', (properties) => {
        const selectedItems =
          properties.item && timelineInstanceRef.current ? timelineInstanceRef.current.getSelection() : [];
        if (selectedItems.length > 0) {
          const selectedItem = selectedItems[0];
          const selectedArticle = articles.find((a) => a.fId === selectedItem);
          if (selectedArticle) {
            setSelectedArticle(selectedArticle);
            setIsPopupOpen(true);
          }
        } else {
          setIsPopupOpen(false);
        }
      });
    } else {
      timelineInstanceRef.current.setItems(articleForTimetable);
    }
  }, [articles, localization, articleForTimetable]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '50px 0' }}>
      <div style={{ minHeight: 200, maxHeight: 200, height: '100%' }}>
        <div ref={timelineRef} style={{ height: 100 }} />
      </div>
      {isPopupOpen && selectedArticle && (
        <PopupWindow article={selectedArticle} style={{ border: '1px solid #000', width: 500 }} />
      )}
    </div>
  );
};

export default TimelineComponent;
