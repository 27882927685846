import { TagValues } from '../Types';

export const getArticleTagValues = (currentArticle, tagValuesDocs) => {
  const values: TagValues[] = tagValuesDocs.docs || [];
  const currentTags: Record<string, string[]> | undefined = currentArticle?.tags;

  const articleTagValues =
    currentTags &&
    Object.values(currentTags).map((v) =>
      v.map((value) => {
        const matchingTag = values.find((el) => el.fId === value);
        return matchingTag?.tagName;
      }),
    );

  return articleTagValues?.flat() ? articleTagValues?.flat() : [];
};
