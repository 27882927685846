import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { Article, TagCategory, TagValues } from '../../../Types';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useArticles, useTagCategoris, useTagValues } from '../../ContextProviders/AppContext';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { faClose, faEdit, faPlus, faSave, faSearch, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tagValueAction } from '../../../Hooks/DatabaseActions';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useFirestore } from '../../ContextProviders/Firebase';
import { toast } from 'react-toastify';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

export const TagManager = () => {
  const [tagCategories, setTagCategories] = useState<TagCategory[]>();
  const [values, setValues] = useState<TagValues[]>([]);

  const [tagName, setTagName] = useState<string>('');
  const [tagValue, setTagValue] = useState<string>('');
  const [tagValues, setTagValues] = useState<{ tagName: string; color?: string }[]>([]);
  const [coloredTags, setColoredTags] = useState<boolean>(false);
  const [tagColor, setTagColor] = useState<string>('');
  const [visibleTags, setVisibleTags] = useState<Record<string, number>>({});

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editTag, setEditTag] = useState<TagCategory>({ categoryName: '', fId: '', coloringTags: false });
  const [editValues, setEditValues] = useState<TagValues[]>([]);
  const [tagValuesEdit, setTagValuesEdit] = useState<TagValues[]>([]);
  const [editTagValueFlag, setEditTagValueFLag] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>();

  const concreteProject = useConcreteProject();
  const firestore = useFirestore();

  const tagCategoriesDocs = useTagCategoris();
  const tagValuesDocs = useTagValues();
  const tagAction = tagValueAction(firestore, concreteProject.id);
  const initialTags = tagCategoriesDocs.docs;
  const localization = useLocalization();

  const [newValues, setNewValue] = useState<string>('');
  const [newColorValues, setColorNewValue] = useState<string>('');
  const articles = useArticles().docs;

  useEffect(() => {
    const sortedCategories = [...tagCategoriesDocs.docs].sort((a, b) => a.categoryName.localeCompare(b.categoryName));
    setTagCategories(sortedCategories);

    // Optionally, sort values if needed elsewhere
    const sortedTags = [...tagValuesDocs.docs].sort((a, b) => a.tagName.localeCompare(b.tagName));
    setValues(sortedTags);
  }, [tagCategoriesDocs, tagValuesDocs]);

  useEffect(() => {
    if (searchTerm) {
      const filteredCategories = initialTags?.filter((t) =>
        t.categoryName.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      const sortedCategories = filteredCategories?.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      setTagCategories(sortedCategories);
    } else {
      const sortedCategories = initialTags?.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      setTagCategories(sortedCategories);
    }
  }, [searchTerm, initialTags]);

  const handleShowMore = (categoryId: string) => {
    setVisibleTags((prevState) => ({
      ...prevState,
      [categoryId]: Infinity,
    }));
  };

  const handleShowLess = (categoryId: string) => {
    setVisibleTags((prevState) => ({
      ...prevState,
      [categoryId]: 10,
    }));
  };

  const addTag = async () => {
    try {
      const newTtag = await tagAction.set(tagName, coloredTags);
      await tagAction.addValues(newTtag.fId, tagValues);
      setTagName('');
      setTagValues([]);
      setColoredTags(false);
      toast.success(localization.strings.settings.tags.createSuccess);
    } catch (err) {
      toast.error(localization.strings.settings.tags.createFailed);
    }
  };

  const searchTags = (event) => {
    setSearchTerm(event.target.value);
  };

  const editTagValue = (categoryId: TagCategory) => {
    setEditMode((prev) => !prev);
    setEditTag(categoryId);
    setEditValues(values?.filter((v) => v.categoryId === categoryId.fId));
  };

  const handleCategoryNameChange = (event) => {
    setEditTag((prev: TagCategory) => ({
      fId: prev.fId,
      categoryName: event.target.value,
      coloringTags: prev.coloringTags,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    try {
      let updatedEditTag = editTag;
      let updatedEditValues = editValues;

      if (!coloredTags) {
        updatedEditTag = { ...editTag, coloringTags: false };
        updatedEditValues = editValues.map((value) => ({
          categoryId: value.categoryId,
          tagName: value.tagName,
          fId: value.fId,
        }));

        await tagAction.updateCategory(updatedEditTag);
        await tagAction.updateValues(updatedEditValues);
      } else {
        updatedEditTag = { ...editTag, coloringTags: true };
        await tagAction.updateCategory(updatedEditTag);
        await tagAction.updateValues(editValues);
      }
      toast.success(localization.strings.settings.tags.updateSccess);
      setEditMode(false);
    } catch (err) {
      toast.error(localization.strings.settings.tags.updateFailed);
    }
  };

  const deleteTagValue = async (valId: string) => {
    try {
      const articlesWithTags = articles.filter((a) => a.tags);
      if (articlesWithTags) {
        const removeFrom = articlesWithTags.filter((k: Article) => {
          return k.tags ? Object.values(k.tags).flat(2).includes(valId) : null;
        });

        removeFrom.map(async (a) => {
          if (a.tags) {
            const newTags = Object.entries(a.tags).map((tags) => {
              if (tags[1].includes(valId)) {
                return { [tags[0]]: tags[1].filter((t) => t !== valId) };
              } else {
                return { [tags[0]]: tags[1] };
              }
            });
            const mergedTags = Object.assign({}, ...newTags);
            await tagAction.addTagsToArticle(a.fId, { tags: mergedTags });
          }
        });
      }

      await tagAction.deleteTagValues([valId]);
      toast.success(localization.strings.settings.tags.deleteSuccess);
      setEditValues((prevState) => prevState?.filter((t) => t.fId !== valId));
    } catch (err) {
      toast.error(localization.strings.settings.tags.deleteFailed);
    }
  };

  const deleteTag = async (tag: TagCategory) => {
    const deleteTagValues = values?.filter((v) => v.categoryId === tag.fId).map((v) => v.fId);
    try {
      setEditValues((prevState) => prevState?.filter((k) => k.categoryId !== tag.fId));
      const articlesWithTags = articles.filter((a) => a.tags);
      if (articlesWithTags) {
        const removeFrom = articlesWithTags.filter((k: Article) => {
          return k.tags ? Object.keys(k.tags).includes(tag.fId) : null;
        });

        removeFrom.map(async (a) => {
          if (a.tags) {
            const newTags = Object.entries(a.tags).map((tags) => {
              if (!tags[0].includes(tag.fId)) {
                return { [tags[0]]: tags[1] };
              } else {
                return null;
              }
            });
            const mergedTags = Object.assign({}, ...newTags);
            await tagAction.addTagsToArticle(a.fId, { tags: mergedTags });
          }
        });
      }
      await tagAction.deleteTagValues(deleteTagValues);
      await tagAction.deleteTagCategory(tag);

      toast.success(localization.strings.settings.tags.deleteSuccess);
    } catch (err) {
      toast.error(localization.strings.settings.tags.deleteFailed);
    }
  };

  const addTagNewValue = async () => {
    try {
      const newTagValue = await tagAction.addValue(editTag.fId, newValues, newColorValues);
      setEditValues((prevState) => {
        const temp = prevState;
        temp?.push(newTagValue);
        return temp;
      });
      setNewValue('');
      setColorNewValue('');
    } catch (err) {
      toast.error(localization.strings.settings.tags.createFailed);
    }
  };

  const handleTagValueChange = (index, event) => {
    const newTagValues = [...tagValuesEdit];
    setEditValues((prevState) =>
      prevState?.map((item) => (item.fId === index ? { ...item, tagName: event.target.value } : item)),
    );

    newTagValues[index] = event.target.value;
    setTagValuesEdit(newTagValues);
  };

  const handleTagColorChange = (index, event) => {
    const newTagValues = [...tagValuesEdit];
    setEditValues((prevState) =>
      prevState?.map((item) => (item.fId === index ? { ...item, color: event.target.value } : item)),
    );

    newTagValues[index] = event.target.value;
    setTagValuesEdit(newTagValues);
  };

  const hasColoredTag = () => {
    return tagCategories?.some((tag) => tag.coloringTags === true);
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.tags.title}</h1>
          <hr />
          <div className="mainContent">
            <div>
              <h5>{localization.strings.settings.tags.addTag}</h5>

              <div>
                <Form>
                  <div style={{ display: 'flex', maxWidth: 500, width: '100%', flexDirection: 'column', gap: 5 }}>
                    <InputGroup className="mb-2">
                      <Input
                        type="text"
                        value={tagName}
                        onChange={(event) => setTagName(event.target.value)}
                        placeholder={localization.strings.settings.tags.tagName}
                        style={{ maxWidth: 200 }}
                      />
                      {tagCategories && !hasColoredTag() && (
                        <div style={{ margin: 'auto 5px' }}>
                          <Input
                            type="checkbox"
                            checked={coloredTags}
                            onChange={() => {
                              setColoredTags((prev) => !prev);
                              if (!coloredTags) {
                                setTagValues((prev) => prev.map((value) => ({ ...value, color: '#000000' })));
                              } else {
                                setTagValues((prev) => prev.map((value) => ({ tagName: value.tagName, color: '' })));
                              }
                            }}
                            id="colorCheck"
                          />
                          <Label for="colorCheck" check>
                            {localization.strings.settings.tags.coloredTag}
                          </Label>
                        </div>
                      )}
                    </InputGroup>
                    <InputGroup className="mb-2">
                      <Input
                        type="text"
                        value={tagValue}
                        onChange={(event) => setTagValue(event.target.value)}
                        placeholder={localization.strings.settings.tags.tagValue}
                        style={{ maxWidth: 200, width: '100%' }}
                      />
                      {!hasColoredTag() && coloredTags && (
                        <Input
                          type="color"
                          style={{ maxWidth: 50, height: 38, width: 50 }}
                          value={tagColor}
                          onChange={(e) => setTagColor(e.target.value)}
                        />
                      )}
                      <InputGroupText style={{ padding: 0, background: '#ffffff' }}>
                        <Button
                          size="sm"
                          onClick={() => {
                            coloredTags
                              ? setTagValues((prevState) => [...prevState, { tagName: tagValue, color: tagColor }])
                              : setTagValues((prevState) => [...prevState, { tagName: tagValue }]);
                            setTagValue('');
                          }}
                          style={{ background: '#ffffff', border: 'none' }}
                          disabled={tagValue.length > 0 ? false : true}
                        >
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </Button>
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </Form>
              </div>

              <div style={{ display: 'flex' }}>
                {tagValues.length > 0 &&
                  tagValues.map((value, index) => {
                    return (
                      <>
                        <Chip
                          key={index}
                          name={value.tagName}
                          color={value.color && value.color}
                          dismissAction={() => setTagValues((prev) => prev.filter((p) => p.tagName !== value.tagName))}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="sm"
                            onClick={() => {
                              setTagValue(value.tagName);
                              setTagValues((prev) => prev.filter((_, position) => position !== index));
                              value.color ? setTagColor(value.color) : setTagColor('');
                            }}
                            style={{ margin: 'auto 5px ', cursor: 'pointer' }}
                          />
                        </Chip>
                      </>
                    );
                  })}

                {editTagValueFlag && (
                  <Button
                    size="sm"
                    onClick={() => setEditTagValueFLag(false)}
                    style={{ background: '#ffffff', border: 'none', cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                )}
              </div>

              <div>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button onClick={addTag} disabled={tagName.length > 0 && tagValues.length > 0 ? false : true}>
                  {localization.strings.settings.tags.addTag}
                </Button>
              </div>
            </div>
          </div>
          <hr />
          <div className="mainContent">
            <div>
              <h5>{localization.strings.settings.tags.editTag}</h5>

              <InputGroup className="mb-2" style={{ maxWidth: 500 }}>
                <Input
                  type="text"
                  placeholder={localization.strings.global.search}
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                ></Input>
                <Button className="m-0">
                  <FontAwesomeIcon icon={faSearch} onClick={searchTags}></FontAwesomeIcon>
                </Button>
              </InputGroup>

              <div>
                {tagCategories?.map((category) => {
                  const categoryTags = values?.filter((tags) => tags.categoryId === category.fId) || [];
                  const maxVisible = visibleTags[category.fId] || 10;

                  return (
                    <ListGroup key={category.fId}>
                      <ListGroupItem>
                        <ListGroupItemHeading className="mb-0">
                          {category.categoryName}
                          <Button
                            size="sm"
                            style={{ margin: '0 10px' }}
                            onClick={() => {
                              editTagValue(category);
                              setColoredTags(hasColoredTag() ? category.coloringTags : false);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                          </Button>
                          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                          <Button size="sm" color="danger" style={{ margin: 0 }} onClick={() => deleteTag(category)}>
                            <FontAwesomeIcon color="danger" icon={faTrash}></FontAwesomeIcon>
                          </Button>
                        </ListGroupItemHeading>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {categoryTags.slice(0, maxVisible).map((tags, index) => (
                            <Chip
                              key={index}
                              name={tags.tagName}
                              color={category.coloringTags ? (tags.color ? tags.color : '#000') : undefined}
                            />
                          ))}
                        </div>
                        {categoryTags.length > 10 && (
                          <Button
                            size="sm"
                            color="link"
                            onClick={() =>
                              maxVisible === 10 ? handleShowMore(category.fId) : handleShowLess(category.fId)
                            }
                          >
                            {maxVisible === 10
                              ? localization.strings.global.showAll
                              : localization.strings.global.showLess}
                          </Button>
                        )}
                      </ListGroupItem>
                    </ListGroup>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={editMode}
          toggle={() => {
            setEditMode(false);
            setColoredTags(false);
          }}
        >
          <ModalHeader toggle={() => setEditMode(false)}>{localization.strings.global.edit}</ModalHeader>
          <ModalBody>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Form onSubmit={handleSubmit}>
              <FormGroup switch>
                <Input
                  type="switch"
                  role="switch"
                  checked={coloredTags}
                  onChange={() => setColoredTags((prev) => !prev)}
                  disabled={hasColoredTag() ? !editTag.coloringTags : false}
                ></Input>
                <Label check>{localization.strings.settings.tags.coloredTag}</Label>
              </FormGroup>
              <InputGroup className="mb-2">
                <Input
                  type="text"
                  defaultValue={tagCategories?.find((cat) => cat.fId === editTag?.fId)?.categoryName}
                  onChange={handleCategoryNameChange}
                ></Input>
              </InputGroup>
              {editValues?.map((t) => (
                <InputGroup className="mb-2" key={t.fId}>
                  <Input
                    type="text"
                    defaultValue={t.tagName}
                    onChange={(event) => handleTagValueChange(t.fId, event)}
                  ></Input>
                  {coloredTags && (
                    <Input
                      type="color"
                      style={{ maxWidth: 50, height: 38, width: 50 }}
                      defaultValue={t.color}
                      onChange={(event) => handleTagColorChange(t.fId, event)}
                    ></Input>
                  )}
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <Button className="m-0" onClick={() => deleteTagValue(t.fId)}>
                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                  </Button>
                </InputGroup>
              ))}
              <InputGroup className="mb-2">
                <Input
                  type="text"
                  value={newValues}
                  onChange={(event) => setNewValue(event.target.value)}
                  placeholder={localization.strings.settings.tags.tagValue}
                />
                {coloredTags && (
                  <Input
                    type="color"
                    style={{ maxWidth: 50, height: 38, width: 50 }}
                    defaultValue={newColorValues}
                    onChange={(event) => setColorNewValue(event.target.value)}
                  ></Input>
                )}
                <InputGroupText style={{ padding: 0, background: '#ffffff' }}>
                  <Button
                    size="sm"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={addTagNewValue}
                    style={{ background: '#ffffff', border: 'none', width: 32 }}
                    disabled={newValues.length > 0 ? false : true}
                  >
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </Button>
                </InputGroupText>
              </InputGroup>
              <Button type="submit" color="success">
                {localization.strings.global.save}
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </main>
    </PageWithSidebar>
  );
};

export const Chip = (props: { name: string; color?: string; dismissAction?: () => void; children?: ReactElement }) => {
  return (
    <div
      style={{
        borderRadius: 20,
        margin: 5,
        border: '1px solid black',
        width: 'fit-content',
        padding: '4px 10px',
        background: '#fff',
      }}
    >
      {props.name}
      {props.color !== undefined && props.color.length > 0 ? (
        <span style={{ color: props.color }}> &#9679;</span>
      ) : null}
      {props.children && props.children}
      {props.dismissAction ? (
        <FontAwesomeIcon
          icon={faClose}
          size="sm"
          style={{ margin: 'auto 5px', cursor: 'pointer' }}
          onClick={props.dismissAction}
        />
      ) : null}
    </div>
  );
};
