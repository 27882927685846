import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/sv.js';
import '../../Froala/custom-image-manager.js';
import '../../Froala/custom-link-article.js';
import '../../Froala/custom-link.js';
import '../../Froala/custom-video.js';
import '../../Froala/custom-checkbox.js';
import '../../Froala/custom-iframe-button.js';
import '../../Froala/custom-anchorlink.js';
import '../../Froala/custom-article-template.js';
import '../../Froala/custom-treaty-template.js';

import { defaultEditorConfig } from '../../Froala/config';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { LinkTypes, checkLinkType } from './LinkTypeChecker';
import { useCategories, useChiefCategories, useImages, useProjectConfig } from '../ContextProviders/AppContext';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import { AppUser, useAuth } from '../ContextProviders/Auth';
import { Category, IsChief, Project } from '../../Types';
import './Editor.scss';
import { Image } from '../../Types/index';
import { useFirebase } from '../ContextProviders/Firebase';
import { FirebaseApp } from 'firebase/app';
import { toasts } from '../../shared';
import { LocalizedStrings } from '../../localizedStrings';
import { LanguageCode } from '@eir/core';
import { initCustomIframe } from '../../Froala/custom-iframe-button.js';
import { initCustomAnchorLink } from '../../Froala/custom-anchorlink.js';
import { initCustomArticleTemplate } from '../../Froala/custom-article-template.js';
import { initCustomTreatyTemplate } from '../../Froala/custom-treaty-template.js';
import { isEqual } from 'lodash';

type CategoryWithChief = Category & IsChief;

interface Props {
  editStatus: boolean;
  onModelChange: (newContent: string) => void;
  content: string;
  className?: string;
  categories: CategoryWithChief[];
  chiefCategories: CategoryWithChief[];
  auth: AppUser;
  images: Image[];
  firebase: FirebaseApp;
  maxHeight?: number;
  concreteProject: Project;
  activeLanguageCode: LanguageCode;
  localizedStrings: LocalizedStrings;
  templateButtons: boolean;
  editorConfig: typeof defaultEditorConfig;
}

interface State {
  initialized: boolean;
}

interface ArticleEditorProps {
  content: string;
  onChange: (newContent: string) => void;
  editStatus: boolean;
  maxHeight?: number;
}
export const ArticleEditor = ({ content, onChange, editStatus, maxHeight }: ArticleEditorProps): ReactElement => {
  const projectConfig = useProjectConfig();
  const auth = useAuth();
  const images = useImages();
  const categories = useCategories();
  const chiefCategories = useChiefCategories();
  const firebase = useFirebase();
  const concreteProject = useConcreteProject();
  const localization = useLocalization();
  const [editorConfig, setEditorConfig] = useState<typeof defaultEditorConfig>({ ...defaultEditorConfig });
  const prevEditorConfig = usePrevious(editorConfig);

  useEffect(() => {
    if (prevEditorConfig && isEqual(prevEditorConfig, editorConfig)) {
      return;
    }
    const _editorConfig = editorConfig;
    if (concreteProject.id !== 'sea_treaties') {
      const index1 = _editorConfig.toolbarButtons.indexOf('articleTemplate');
      const index2 = _editorConfig.toolbarButtons.indexOf('treatyTemplate');
      if (index1 !== -1) {
        _editorConfig.toolbarButtons.splice(index1, 1);
      }
      if (index2 !== -1) {
        _editorConfig.toolbarButtons.splice(index2, 1);
      }
    }
    if (concreteProject && !concreteProject.enableInsertArticle) {
      const index3 = _editorConfig.toolbarButtons.indexOf('showArticle');
      if (index3 !== -1) {
        _editorConfig.toolbarButtons.splice(index3, 1);
      }
    }
    setEditorConfig({ ..._editorConfig });
  }, [concreteProject, editorConfig, prevEditorConfig]);

  if (
    !auth ||
    projectConfig.loading ||
    projectConfig.error ||
    images.loading ||
    images.error ||
    categories.loading ||
    categories.error
  )
    return <></>;

  return (
    <ArticleEditorOOP
      editStatus={editStatus}
      content={content}
      onModelChange={(newContent: string) => {
        onChange(newContent);
      }}
      maxHeight={maxHeight}
      {...{
        auth,
        images: images.docs,
        categories: categories.docs,
        chiefCategories: chiefCategories.docs,
        firebase,
        concreteProject,
      }}
      activeLanguageCode={localization.activeLanguageCode}
      localizedStrings={localization.strings}
      templateButtons={concreteProject.id === 'sea_treaties'}
      editorConfig={editorConfig}
    />
  );
};

// Froala editor must be class component, since using a ref created from useRef() is not supported
class ArticleEditorOOP extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }
  editorRef = React.createRef<FroalaEditor>();
  container: HTMLDivElement;

  render(): React.ReactNode {
    const { maxHeight, images, chiefCategories, firebase, concreteProject, templateButtons } = this.props;
    initCustomIframe();
    initCustomAnchorLink();

    if (templateButtons) {
      initCustomArticleTemplate();
      initCustomTreatyTemplate();
    }

    return (
      <div className="editor-wrapper" id="editor-wrapper" style={{ maxHeight: maxHeight, minHeight: 300 }}>
        <FroalaEditor
          ref={this.editorRef}
          tag="textarea"
          config={Object.assign(
            {
              ...this.props.editorConfig,
              language: this.props.activeLanguageCode,
              fileUploadURL: `https://europe-west3-${firebase.options.projectId}.cloudfunctions.net/POSTUpload/file`,
              imageUploadURL: `https://europe-west3-${firebase.options.projectId}.cloudfunctions.net/POSTUpload/image`,
              fileMaxSize: 9.5 * 1024 * 1024,
              imageMaxSize: 9.5 * 1024 * 1024,
              events: {
                initialized: this.editorInitialized,
                'imageManager.beforeImagesLoaded': (e) => this.beforeImagesLoaded(images),
                'link.beforeInsert': (link, text, attrs) => this.beforeLinkInsert(link, text, attrs, chiefCategories),
                'paste.afterCleanup': (pastedContent) => this.urlAutoLink(pastedContent, chiefCategories),
                'file.error': (err) => this.fileError(err),
                'showArticle.beforeInsert': (link, text, attrs) =>
                  this.beforeLinkInsert(link, text, attrs, chiefCategories),
              },
            },
            concreteProject.web?.froalaConfigOverride,
          )}
          model={this.props.content}
          onModelChange={(newContent: string): void => {
            if (this.props.editStatus && this.state.initialized && newContent !== this.props.content) {
              this.props.onModelChange(newContent);
            }
          }}
        />
      </div>
    );
  }

  private fileError = (err) => {
    if (err.code === 5) toasts.error(this.props.localizedStrings.article.fileTooLarge);
  };

  private editorInitialized = () => {
    this.setState({ initialized: true });
  };

  private urlAutoLink = (pastedContent: string, chiefCategories: CategoryWithChief[]) => {
    if (/^<a.*>.*<\/a>/i.test(pastedContent)) {
      const element = document.createElement('div');
      element.innerHTML = pastedContent.trim();

      const anchor = element.firstChild as HTMLAnchorElement;
      const { href, text } = this.beforeLinkInsert(anchor.href, '', {}, chiefCategories);

      if (href.includes('?path=')) {
        const [base, queryString] = href.split('?path=');
        return `<a href="${base}?path=${decodeURIComponent(queryString)}">${text}</a>`;
      } else {
        return `<a href="${href}">${text}</a>`;
      }
    }
    return pastedContent;
  };

  private beforeImagesLoaded = (images: Image[]): void => {
    const editor = this.editorRef.current?.getEditor();

    editor.events.trigger('imageManager.imagesLoaded');
    editor.imageManager.processLoadedImages(this.prepareGallery(images));
  };

  // eslint-disable-next-line
  private beforeLinkInsert = (link: string, text: string, attrs: any, chiefCategories: CategoryWithChief[]) => {
    const isCategoryChief = (cid: string) => chiefCategories.some((c) => c.fId === cid);

    let finalLink;
    if (link.startsWith('#')) {
      finalLink = link;
      if (this.isEmptyOrWhiteSpaces(text)) {
        text = `Anchorlink`;
      }
      return { href: finalLink, text, attrs };
    }
    try {
      const url = new URL(link);
      const theLinkType = checkLinkType(url);

      if (theLinkType === LinkTypes.internalArticle) {
        const urlArray = url.pathname.split('/');
        const articleId = urlArray.pop();
        const categoryId = urlArray[urlArray.length - 1];

        const isChief: boolean = isCategoryChief(categoryId) ? true : false;
        const isChangelog: boolean = categoryId === 'changelog';
        if (isChief) {
          finalLink = `/chief-articles/${articleId}${url.hash}`;
        } else if (isChangelog) {
          finalLink = `/changelog-articles/${articleId}${url.hash}`;
        } else {
          finalLink = `/articles/${articleId}${url.hash}`;
        }
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${
            this.props.localizedStrings.global.linkTo
          } ${this.props.localizedStrings.article.article.toLowerCase()} ${articleId}`;
          if (url.hash) {
            text += '(anchorlink)';
          }
        }
      } else if (theLinkType === LinkTypes.internalCategory) {
        const categoryId = url.pathname.split('/').pop();
        // eslint-disable-next-line
        const isChief: boolean = isCategoryChief(categoryId!) ? true : false;
        if (isChief) {
          finalLink = `/chief-categories/${categoryId}`;
        } else {
          finalLink = `/categories/${categoryId}`;
        }
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${
            this.props.localizedStrings.global.linkTo
          } ${this.props.localizedStrings.category.category.toLowerCase()} ${categoryId}`;
        }
      } else if (theLinkType === LinkTypes.internalAbout) {
        finalLink = `/about`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${
            this.props.localizedStrings.global.linkTo
          } ${this.props.localizedStrings.global.homePage.toLowerCase()}`;
        }
      } else if (theLinkType === LinkTypes.internalExternal) {
        const categoryId = url.pathname.split('/').pop();
        finalLink = `/externals/${categoryId}${url.search}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${
            this.props.localizedStrings.global.linkTo
          } ${this.props.localizedStrings.external.externalContent.toLowerCase()} ${categoryId}`;
        }
      } else if (theLinkType === LinkTypes.internalPolicy) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${this.props.localizedStrings.global.linkTo} ${id}`;
        }
      } else if (theLinkType === LinkTypes.internalTerms) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `${this.props.localizedStrings.global.linkTo} ${id}`;
        }
      } else if (theLinkType === LinkTypes.external) {
        finalLink = link;
        if (this.isEmptyOrWhiteSpaces(text)) {
          const name = url.pathname.split('/')[1];
          text = `${this.props.localizedStrings.global.linkTo} ${url.hostname} [${name}]`;
        }
      }

      return { href: finalLink, text, attrs };
    } catch (_) {
      return {
        href: link,
        text,
        attrs,
      };
    }
  };

  private prepareGallery(images: Image[]): {
    url: string;
    thumb: string;
  }[] {
    return images.map((image) => ({ url: image.resourceUrl, thumb: image.resourceUrl }));
  }

  private isEmptyOrWhiteSpaces(str: string): boolean {
    return str === null || str.match(/^ *$/) !== null;
  }
}

/**
 * usePrevious - A custom hook to get the previous value of a state or prop
 * @param value The current value
 * @returns The previous value
 */
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value; // Update ref with current value after render
  });

  return ref.current;
}

export default usePrevious;
