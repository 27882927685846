import { AgroScanMutation, AgroScanQuery, ArticleData, DateOfSigning, SectionData } from '@eir/core';
import {
  collection,
  addDoc,
  doc,
  Firestore,
  updateDoc,
  deleteDoc,
  writeBatch,
  deleteField,
  onSnapshot,
  getDoc,
  setDoc,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { deleteObject, StorageReference } from 'firebase/storage';
import { CategoryTreeNorm } from '../components/Sidebar/CategorySort/CategoryNavEdit';
import { User, deleteUser } from 'firebase/auth';
import {
  Article,
  ArticleValue,
  Category,
  ExternalContent,
  IsChangelog,
  IsChief,
  ProjectConfig,
  PublishResult,
  Section,
  Subscription,
  ChiefCategory,
  TagCategory,
  TagValues,
} from '../Types';
import { FirestoreCollection } from './Database';

export enum Collection {
  Project = 'project',
  Platform = 'platform',
  Resources = 'resources',
  Category = 'category',
  Section = 'section',
  Article = 'article',
  Invocation = 'invocation',
  UploadedImage = 'uploadedImage',
  Configuration = 'configuration',
  EXTERNAL = 'externals',
  Features = '_features',
  AccessRoles = 'accessRoles',
  Chief = 'chief',
  Changelog = 'changelog',
  Helpers = 'aggregatedHelpers',
  Subscriptions = 'subscriptions',
  Stripe = 'stripe',
  AgroScan = 'agroScan',
  Users = 'users',
}

export enum Invocation {
  Delete = 'delete',
  PublishProcess = 'publishProcess',
  PinProtect = 'pinProtect',
  ProtectCategory = 'protectCategory',
  ChangeStaffCredentials = 'changeStaffCredentials',
  ChangeAccountCredentials = 'changeAccountCredentials',
  Export = 'static_export',
}

export enum InvocationDummies {
  Article = 'i_article',
  Section = 'i_section',
  Category = 'i_category',
  Image = 'i_image',
  Video = 'i_video',
  Project = 'i_project',
}

export const chiefChangelogCategoryActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    sort: async (tree: CategoryTreeNorm): Promise<void> => {
      const batch = writeBatch(firestore);
      tree.sections.forEach((section, i) => {
        if (section.fId !== 'DEFAULT_SECTION')
          batch.set(
            doc(
              collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`),
              section.fId,
            ),
            { orderIndex: i },
            { merge: true },
          );
        section.articles.forEach((article, i) => {
          batch.set(
            doc(
              collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}`),
              article.fId,
            ),
            { orderIndex: i, section: section.fId === 'DEFAULT_SECTION' ? null : section.fId },
            { merge: true },
          );
        });
      });
      return batch.commit();
    },
  };
};
export const chiefChangelogArticleActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    create: async (article: ArticleData): Promise<Article & IsChief & IsChangelog> => {
      const addedRef = await addDoc(
        collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}`),
        article,
      );
      return {
        ...(await getDoc(addedRef)).data(),
        fId: addedRef.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Article & IsChief & IsChangelog;
    },
    remove: (articleId: string) =>
      deleteDoc(doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}/${articleId}`)),
    update: async (articleId: string, articleUpdate: Partial<ArticleData>) => {
      const { name, content, orderIndex, category, section } = articleUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, content, orderIndex, section, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}/${articleId}`);
      await updateDoc(ref, maximumKeysUpdate);
      return {
        ...(await getDoc(ref)).data(),
        fId: ref.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Article & IsChief & IsChangelog;
    },
  };
};
export const chiefChangelogSectionActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    create: async (section: SectionData): Promise<Section & IsChief & IsChangelog> => {
      const addedRef = await addDoc(
        collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`),
        section,
      );
      return {
        ...(await getDoc(addedRef)).data(),
        fId: addedRef.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Section & IsChief & IsChangelog;
    },
    remove: (sectionId: string) =>
      deleteDoc(doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}/${sectionId}`)),
    update: async (sectionId: string, sectionUpdate: Partial<ArticleData>) => {
      const { name, orderIndex, category } = sectionUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, orderIndex, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}/${sectionId}`);
      await updateDoc(ref, maximumKeysUpdate);
      return {
        ...(await getDoc(ref)).data(),
        fId: ref.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Section & IsChief & IsChangelog;
    },
    sortAllSections: async (categoryId: string): Promise<Section[]> => {
      try {
        const collectionRef = collection(
          firestore,
          `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`,
        );

        const q = query(collectionRef, where('category', '==', categoryId));

        const querySnapshot = await getDocs(q);

        const sections: Section[] = [];

        querySnapshot.forEach((docSnapshot) => {
          const data = docSnapshot.data() as Section;
          data.fId = docSnapshot.id;
          sections.push(data);
        });

        sections.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

        sections.forEach((section, index) => {
          section.orderIndex = index + 1;
        });

        for (let i = 0; i < sections.length; i += 500) {
          const batch = writeBatch(firestore);
          const batchSections = sections.slice(i, i + 500);

          batchSections.forEach((section) => {
            const docRef = doc(
              firestore,
              `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`,
              section.fId,
            );
            batch.update(docRef, { orderIndex: section.orderIndex });
          });

          try {
            await batch.commit();
          } catch (error) {
            console.error('Error updating batch: ', error);
            throw error;
          }
        }
        return sections;
      } catch (error) {
        console.error('Error fetching or updating sections: ', error);
        throw error;
      }
    },
  };
};

export const changelogArticleActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogArticleActions(firestore, projectId, Collection.Changelog);
export const changelogSectionActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogSectionActions(firestore, projectId, Collection.Changelog);
export const changelogCategoryActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogCategoryActions(firestore, projectId, Collection.Changelog);
export const chiefArticleActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogArticleActions(firestore, projectId, Collection.Chief);
export const chiefSectionActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogSectionActions(firestore, projectId, Collection.Chief);
export const chiefCategoryActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogCategoryActions(firestore, projectId, Collection.Chief);

/* STANDARD CATEGORY */
export const articleActions = (firestore: Firestore, projectId: string, currentUser: string | null = '?') => {
  currentUser = currentUser || '?';

  return {
    create: async (article: ArticleData): Promise<Article & IsChief & IsChangelog> => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/${Collection.Article}Draft`), {
        ...article,
        state: 'created',
        lastUpdatedBy: currentUser,
      });

      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as Article & IsChief & IsChangelog;
    },
    remove: async (articleId: string, userId: string) => {
      const removedRef = doc(firestore, `project/${projectId}/${Collection.Article}Draft/${articleId}`);
      await updateDoc(removedRef, { deleted: true, state: 'deleted', lastUpdatedBy: currentUser });
      return { ...(await getDoc(removedRef)).data(), fId: removedRef.id } as Article & IsChief & IsChangelog;
    },
    update: async (
      articleId: string,
      articleUpdate: Partial<ArticleData>,
    ): Promise<Article & IsChief & IsChangelog> => {
      const { name, content, deleted, section, category } = articleUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, content, deleted, section, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${Collection.Article}Draft/${articleId}`);
      await updateDoc(ref, {
        ...maximumKeysUpdate,
        deleted: deleted || deleteField(),
        state: (await getDoc(ref)).data()?.state === 'created' ? 'created' : 'updated',
        lastUpdatedBy: currentUser,
      });

      return { ...(await getDoc(ref)).data(), fId: ref.id } as Article & IsChief & IsChangelog;
    },
  };
};

export const sectionActions = (firestore: Firestore, projectId: string) => {
  return {
    create: async (section: SectionData) => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/${Collection.Section}Draft`), section);
      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as Section;
    },
    remove: async (sectionId: string, userId: string) => {
      const removeRef = doc(firestore, `project/${projectId}/${Collection.Section}Draft/${sectionId}`);
      await updateDoc(removeRef, { deleted: true });
      return { ...(await getDoc(removeRef)).data(), fId: removeRef.id } as Section;
    },
    update: async (sectionId: string, sectionUpdate: Partial<SectionData>): Promise<Section> => {
      const { name, deleted, category } = sectionUpdate;
      const ref = doc(firestore, `project/${projectId}/${Collection.Section}Draft/${sectionId}`);
      await updateDoc(ref, { name, deleted: deleted === true ? true : deleteField(), category });
      return { ...(await getDoc(ref)).data(), fId: ref.id } as Section;
    },
    sortAllSections: async (categoryId: string): Promise<Section[]> => {
      try {
        const collectionRef = collection(firestore, `project/${projectId}/${Collection.Section}Draft`);

        const q = query(collectionRef, where('category', '==', categoryId));

        const querySnapshot = await getDocs(q);

        const sections: Section[] = [];

        querySnapshot.forEach((docSnapshot) => {
          const data = docSnapshot.data() as Section;
          data.fId = docSnapshot.id;
          sections.push(data);
        });

        sections.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

        sections.forEach((section, index) => {
          section.orderIndex = index + 1;
        });

        for (let i = 0; i < sections.length; i += 500) {
          const batch = writeBatch(firestore);
          const batchSections = sections.slice(i, i + 500);

          batchSections.forEach((section) => {
            const docRef = doc(firestore, `project/${projectId}/${Collection.Section}Draft`, section.fId);
            batch.update(docRef, { orderIndex: section.orderIndex });
          });

          try {
            await batch.commit();
          } catch (error) {
            console.error('Error updating batch: ', error);
            throw error;
          }
        }
        return sections;
      } catch (error) {
        console.error('Error fetching or updating sections: ', error);
        throw error;
      }
    },
  };
};

export const appActions = (firestore: Firestore, projectId: string) => {
  return {
    publish: async (
      userId: string,
      notifyMessage: string | undefined,
      shouldSendNotification: boolean,
      shouldNotifyAll: boolean | undefined,
      targetUsers: string[] | undefined,
      articles: string[],
      allowedCategoriesToEdit: string[],
    ): Promise<PublishResult> => {
      try {
        const report = await addDoc(
          collection(
            firestore,
            `${Collection.Invocation}/${Invocation.PublishProcess}/${InvocationDummies.Project}/${projectId}/${userId}`,
          ),
          { shouldSendNotification, shouldNotifyAll, targetUsers, notifyMessage, articles, allowedCategoriesToEdit },
        );

        return new Promise<PublishResult>((resolve, reject) => {
          const unsub = onSnapshot(
            report,
            (snap) => {
              const result = snap.data() as PublishResult;
              if (result.completedOn) {
                resolve(result);
                unsub();
              }
            },
            (error) => {
              reject(error);
              unsub();
            },
          );
          setTimeout(() => {
            reject(new Error('Timeout in publish process'));
            unsub();
          }, 30000);
        });
      } catch (error) {
        console.log(error);
        return new Promise((resolve, reject) => reject(error));
      }
    },
  };
};

export const iconActions = (projectId: string) => {
  const url = `https://europe-west3-${projectId}.cloudfunctions.net/POSTUpload/image`;

  return {
    create: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const responseDate = await response.json();
      const resourceUrl = responseDate.link;

      return resourceUrl;
    },
  };
};

export const categoryActions = (firestore: Firestore, projectId: string) => {
  return {
    sort: async (tree: CategoryTreeNorm): Promise<void> => {
      const batch = writeBatch(firestore);
      tree.sections.forEach((section, i) => {
        if (section.fId !== 'DEFAULT_SECTION')
          batch.set(
            doc(collection(firestore, `project/${projectId}/${Collection.Section}Draft`), section.fId),
            { orderIndex: i },
            { merge: true },
          );
        section.articles.forEach((article, i) => {
          batch.set(
            doc(collection(firestore, `project/${projectId}/${Collection.Article}Draft`), article.fId),
            { orderIndex: i, section: section.fId === 'DEFAULT_SECTION' ? null : section.fId },
            { merge: true },
          );
        });
      });
      return batch.commit();
    },
    customerSorting: async (categories) => {
      try {
        for (const [index, category] of categories.entries()) {
          if (category.isChief) {
            const docRef = doc(firestore, `project/${projectId}/${Collection.Chief}/db/category/${category.fId}`);
            await updateDoc(docRef, {
              orderIndex: index,
            });
          } else if (category.type === 'html' || category.type === 'workorder') {
            const docRef = doc(firestore, `project/${projectId}/${Collection.EXTERNAL}/${category.fId}`);
            await updateDoc(docRef, {
              orderIndex: index,
            });
          } else {
            const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${category.fId}`);
            const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/${category.fId}`);
            await updateDoc(docRef, {
              orderIndex: index,
            });
            await updateDoc(docRefDraft, {
              orderIndex: index,
            });
          }
        }
      } catch (error) {
        console.error('There was an error in customerSorting', error);
        return false;
      }
      return true;
    },
    //Returns true if the category already exists. If it does we should not upload image or new category.
    exists: async (name: string, type: string) => {
      const categoryId = name.replace(/ /g, '').toLowerCase();

      if (type === 'changelog') {
        const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/changelog`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return true;
        }
      } else {
        const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${categoryId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return true;
        }
      }
      return false;
    },
    create: async (
      name: string,
      icon: string | null,
      type: string,
      availableMobile: boolean,
      availableWeb: boolean,
      orderIndex: number,
      url: string,
    ) => {
      try {
        const categoryId = name.replace(/ /g, '').toLowerCase();
        const isProtected = false;
        const lastUpdated = new Date();
        const siblingDocReference = doc(firestore, `project/${projectId}/${Collection.Category}/${categoryId}`);

        if (type === 'changelog') {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/changelog`);
          await setDoc(docRef, {
            availableMobile,
            availableWeb,
            icon,
            isProtected,
            lastUpdated,
            name,
            orderIndex,
            type: 'articleCategory',
          });

          const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/changelog`);
          await setDoc(docRefDraft, {
            availableMobile,
            availableWeb,
            icon,
            isProtected,
            lastUpdated,
            isChangelog: true,
            name,
            orderIndex,
            siblingDocReference,
            type: 'articleCategory',
          });
        } else if (type === 'url') {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${categoryId}`);
          await setDoc(docRef, {
            availableMobile,
            availableWeb: false,
            icon,
            isProtected,
            lastUpdated,
            name,
            orderIndex,
            type,
            url,
          });
          const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/${categoryId}`);
          await setDoc(docRefDraft, {
            availableMobile,
            availableWeb: false,
            icon,
            isProtected,
            lastUpdated,
            name,
            orderIndex,
            siblingDocReference,
            type,
            url,
          });
        } else {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${categoryId}`);
          await setDoc(docRef, {
            availableMobile,
            availableWeb,
            icon,
            isProtected,
            lastUpdated,
            name,
            orderIndex,
            type,
          });

          const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/${categoryId}`);
          await setDoc(docRefDraft, {
            availableMobile,
            availableWeb,
            icon,
            isProtected,
            lastUpdated,
            name,
            orderIndex,
            siblingDocReference,
            type,
          });
        }
        return true;
      } catch (error) {
        console.error('Error in categoryActions.create', error);
        return false;
      }
    },
    edit: async (
      category: Category | ExternalContent | ChiefCategory,
      newName: string,
      newIcon: string | null,
      type: string,
      availableMobile: boolean,
      availableWeb: boolean,
      newUrl: string,
    ) => {
      try {
        const lastUpdated = new Date();
        if (type === 'articleCategory' || type === 'changelog' || type === 'nativeModule') {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${category.fId}`);
          const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/${category.fId}`);

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              name: newName,
              icon: newIcon || docSnap.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: availableWeb,
              lastUpdated: lastUpdated,
            });
          }

          const docSnapDraft = await getDoc(docRefDraft);
          if (docSnapDraft.exists()) {
            await updateDoc(docRefDraft, {
              name: newName,
              icon: newIcon || docSnapDraft.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: availableWeb,
              lastUpdated: lastUpdated,
            });
          }
        } else if (type === 'url') {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Category}/${category.fId}`);
          const docRefDraft = doc(firestore, `project/${projectId}/${Collection.Category}Draft/${category.fId}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              name: newName,
              icon: newIcon || docSnap.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: docSnap.data().availableWeb,
              lastUpdated: lastUpdated,
              url: newUrl,
            });
          }
          const docSnapDraft = await getDoc(docRefDraft);
          if (docSnapDraft.exists()) {
            await updateDoc(docRefDraft, {
              name: newName,
              icon: newIcon || docSnapDraft.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: docSnapDraft.data().availableWeb,
              lastUpdated: lastUpdated,
              url: newUrl,
            });
          }
        }
        return true;
      } catch (error) {
        console.error('Error in categoryActions.edit', error);
        return false;
      }
    },
  };
};

export const externalActions = (firestore: Firestore, projectId: string) => {
  return {
    create: async (
      name: string,
      icon: string | null,
      type: string,
      availableMobile: boolean,
      availableWeb: boolean,
      orderIndex: number,
      categoryId: string,
    ) => {
      const isProtected = false;
      const content = '';

      try {
        const docRef = doc(firestore, `project/${projectId}/${Collection.EXTERNAL}/${categoryId}`);
        await setDoc(docRef, {
          availableMobile,
          availableWeb,
          content,
          icon,
          isProtected,
          name,
          orderIndex,
          type,
        });
        return true;
      } catch (error) {
        console.error('Error in externalActions.create', error);
        return false;
      }
    },
    edit: async (
      category: Category | ExternalContent | ChiefCategory,
      newName: string,
      newIcon: string | null,
      availableMobile: boolean,
      availableWeb: boolean,
    ) => {
      try {
        const docRef = doc(firestore, `project/${projectId}/${Collection.EXTERNAL}/${category.fId}`);
        const docSnap = await getDoc(docRef);
        const lastUpdated = new Date();

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            name: newName,
            icon: newIcon || docSnap.data().icon, // Keep old icon if new icon is not provided
            availableMobile: availableMobile,
            availableWeb: availableWeb,
            lastUpdated: lastUpdated,
          });
          return true;
        }
        return true;
      } catch (error) {
        console.error('Error in externalActions.edit', error);
        return false;
      }
    },
    exists: async (name: string) => {
      const categoryId = name.replace(/ /g, '').toLowerCase();

      const docRef = doc(firestore, `project/${projectId}/${Collection.EXTERNAL}/${categoryId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return true;
      }
      return false;
    },
  };
};

export const chiefCategoryFirebaseActions = (firestore: Firestore, projectId: string) => {
  return {
    create: async (
      name: string,
      icon: string | null,
      type: string,
      availableMobile: boolean,
      availableWeb: boolean,
      orderIndex: number,
      url: string,
    ) => {
      try {
        const categoryId = name.replace(/ /g, '').toLowerCase();
        if (type === 'url') {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Chief}/db/category/${categoryId}`);
          await setDoc(docRef, {
            availableMobile,
            availableWeb: false,
            icon,
            name,
            orderIndex,
            type,
            url,
          });
        } else {
          const docRef = doc(firestore, `project/${projectId}/${Collection.Chief}/db/category/${categoryId}`);
          await setDoc(docRef, {
            availableMobile,
            availableWeb,
            icon,
            name,
            orderIndex,
            type,
          });
        }
        return true;
      } catch (error) {
        console.error('Error in chiefCategoryFirebaseActions.create', error);
        return false;
      }
    },
    edit: async (
      category: Category | ExternalContent | ChiefCategory,
      newName: string,
      newIcon: string | null,
      type: string,
      availableMobile: boolean,
      availableWeb: boolean,
      newUrl: string,
    ) => {
      try {
        const lastUpdated = new Date();
        const docRef = doc(firestore, `project/${projectId}/${Collection.Chief}/db/category/${category.fId}`);
        const docSnap = await getDoc(docRef);

        if (type === 'url') {
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              name: newName,
              icon: newIcon || docSnap.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: docSnap.data().availableWeb,
              lastUpdated: lastUpdated,
              url: newUrl,
            });
          }
        } else {
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              name: newName,
              icon: newIcon || docSnap.data().icon, // Keep old icon if new icon is not provided
              availableMobile: availableMobile,
              availableWeb: availableWeb,
              lastUpdated: lastUpdated,
            });
          }
        }
        return true;
      } catch (error) {
        console.error('Error in chiefCategoryFirebaseActions.edit', error);
        return false;
      }
    },
    exists: async (name: string) => {
      const categoryId = name.replace(/ /g, '').toLowerCase();

      const docRef = doc(firestore, `project/${projectId}/${Collection.Chief}/db/category/${categoryId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return true;
      }
      return false;
    },
  };
};

export const configActions = (firestore: Firestore, projectId: string) => {
  return {
    update: (configUpdate: Partial<ProjectConfig>) =>
      updateDoc(doc(firestore, `${Collection.Project}/${projectId}/${Collection.Configuration}/draft`), configUpdate),
  };
};

export const tagActions = (firestore: Firestore) => {
  return {
    set: async (tags: string[] | undefined): Promise<void> =>
      await updateDoc(doc(firestore, `configuration/project`), { tags: tags }),
  };
};

export const subscriptionsActions = (firestore: Firestore) => {
  return {
    update: (stripeProductId: string, subscriptionUpdate: Partial<Subscription>) =>
      updateDoc(
        doc(firestore, `${Collection.Stripe}/data/${Collection.Subscriptions}/${stripeProductId}`),
        subscriptionUpdate,
      ),
    delete: (stripeProductId: string) =>
      deleteDoc(doc(firestore, `${Collection.Stripe}/data/${Collection.Subscriptions}/${stripeProductId}`)),
    sort: (subscriptions: { productId: string; orderIndex: number }[]) => {
      const batch = writeBatch(firestore);
      subscriptions.forEach(({ orderIndex, productId }) => {
        batch.set(
          doc(firestore, `${Collection.Stripe}/data/${Collection.Subscriptions}/${productId}`),
          { orderIndex },
          { merge: true },
        );
      });

      return batch.commit();
    },
  };
};

// TODO: Remove these actions as it should be a sideeffect of payment processing or canceling subscription in stripe
export const subscriptionUserActions = (firestore: Firestore, userId: string) => {
  return {
    subscribe: (stripeProductId: string, stripePriceId: string) =>
      updateDoc(doc(firestore, `${Collection.Stripe}/data/${Collection.Users}/${userId}`), {
        subscription: stripeProductId,
        priceId: stripePriceId,
        subscribedFrom: new Date(),
        subscribedTo: new Date('2025-01-01'),
      }),
    cancel: () =>
      updateDoc(doc(firestore, `${Collection.Stripe}/data/${Collection.Users}/${userId}`), {
        subscription: deleteField(),
        priceId: deleteField(),
        subscribedFrom: deleteField(),
        subscribedTo: deleteField(),
      }),
  };
};

interface TestData {
  tags?: Record<string, string[]>;
  dateOfSigning?: DateOfSigning;
}

export const tagValueAction = (firestore: Firestore, projectId: string) => {
  return {
    set: async (category: string, coloringTags: boolean): Promise<TagCategory> => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/tagCategories`), {
        categoryName: category,
        coloringTags: coloringTags,
      });
      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as TagCategory;
    },

    addValues: async (categoryId: string, values: { tagName: string; color?: string }[]): Promise<void> => {
      await Promise.all(
        values.map(async (tag) => {
          const addedRef = await addDoc(collection(firestore, `project/${projectId}/tags`), {
            categoryId,
            tagName: tag.tagName,
            color: tag.color,
          });
          return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as TagValues;
        }),
      );
    },

    addValue: async (categoryId: string, tagName: string, color?: string): Promise<TagValues> => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/tags`), {
        categoryId,
        tagName,
        color: color,
      });
      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as TagValues;
    },

    updateValues: async (tags: TagValues[]): Promise<void> => {
      await Promise.all(
        tags.map(async (tag) => {
          await updateDoc(doc(firestore, `project/${projectId}/tags/${tag.fId}`), {
            tagName: tag.tagName,
            color: tag.color ? tag.color : '',
          });
        }),
      );
    },

    updateCategory: async (tagCat: TagCategory): Promise<void> => {
      await updateDoc(doc(firestore, `project/${projectId}/tagCategories/${tagCat.fId}`), {
        categoryName: tagCat.categoryName,
        coloringTags: tagCat.coloringTags,
      });
    },

    deleteTagValues: async (tagValIds: string[]): Promise<void> => {
      await Promise.all(
        tagValIds.map(async (tagVal) => {
          await deleteDoc(doc(firestore, `project/${projectId}/tags/${tagVal}`));
        }),
      );
    },

    deleteTagCategory: async (tagCat: TagCategory): Promise<void> =>
      await deleteDoc(doc(firestore, `project/${projectId}/tagCategories/${tagCat.fId}`)),

    addTagsToArticle: async (articleId: string, data: TestData): Promise<void> => {
      const docRef = doc(firestore, `project/${projectId}/article/${articleId}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await Promise.all([
          updateDoc(doc(firestore, `project/${projectId}/article/${articleId}`), { ...data }),
          updateDoc(doc(firestore, `project/${projectId}/articleDraft/${articleId}`), { ...data }),
        ]);
      } else {
        await Promise.all([updateDoc(doc(firestore, `project/${projectId}/articleDraft/${articleId}`), { ...data })]);
      }
    },
  };
};

export const articleValueActions = (firestore: Firestore, projectId: string) => {
  const updateArticleValues = async (article: Article, data: Object, values: FirestoreCollection<ArticleValue>) => {
    if (!values.docs.map((a) => a.fId).includes(article.fId)) {
      await setDoc(doc(firestore, `project/${projectId}/articleValue/${article.fId}`), data);
    } else {
      for (const [key, value] of Object.entries(data))
        await updateDoc(doc(firestore, `project/${projectId}/articleValue/${article.fId}`), { [key]: value });
    }
  };

  return {
    update: async (article: Article, data: Object, values: FirestoreCollection<ArticleValue>): Promise<void> =>
      updateArticleValues(article, data, values),

    updateAll: async (articles: Article[], data: Object, values: FirestoreCollection<ArticleValue>): Promise<void> => {
      for (const article of articles) await updateArticleValues(article, data, values);
    },

    delete: async (article: Article) =>
      await deleteDoc(doc(firestore, `project/${projectId}/articleValue/${article.fId}`)),

    deleteAll: async (articles: Article[]): Promise<void> => {
      for (const article of articles)
        await deleteDoc(doc(firestore, `project/${projectId}/articleValue/${article.fId}`));
    },
  };
};

export const accountActions = (firestore: Firestore, projectId: string) => {
  return {
    updateAdminPassword: (newPassword: string, userId: string) =>
      addDoc(
        collection(
          firestore,
          `${Collection.Invocation}/${Invocation.ChangeAccountCredentials}/${InvocationDummies.Project}/${projectId}/${userId}`,
        ),
        { password: newPassword },
      ),
    setPermission: (categoryId: string, userId: string, canRead: boolean) =>
      setDoc(doc(firestore, `cmsUser/${userId}`), { categoryPermissions: { [categoryId]: canRead } }, { merge: true }),
    deleteUser: async (user: User) => {
      await deleteUser(user);
    },
  };
};

export const invocationActions = (firestore: Firestore, projectId: string) => ({
  export: () =>
    new Promise<void>((resolve, reject) => {
      let count = 0;
      const unsub = onSnapshot(
        collection(firestore, `project/${projectId}/exports`),
        () => {
          if (count === 1) {
            unsub();
            resolve();
          }
          count++;
        },
        (err) => {
          unsub();
          reject(err);
        },
      );
      void setDoc(doc(firestore, `${Collection.Invocation}/${Invocation.Export}`), {});
    }),
  deleteExports: (zipRef: StorageReference) =>
    new Promise<void>((resolve, reject) => {
      const unsub = onSnapshot(
        collection(firestore, `project/${projectId}/exports`),
        (snap) => {
          if (snap.docs.length > 0) {
            void Promise.all(snap.docs.map((d) => deleteDoc(d.ref), deleteObject(zipRef)))
              .then(() => {
                unsub();
                resolve();
              })
              .catch((err) => {
                unsub();
                reject(err);
              });
          }
        },
        (err) => {
          unsub();
          reject(err);
        },
      );
    }),
});

export const agroScanAccessActions = (firestore: Firestore, userId: string) => {
  return {
    update: (entityName: AgroScanMutation | AgroScanQuery, value: boolean) =>
      updateDoc(doc(firestore, `${Collection.AgroScan}/users/${userId}/${Collection.Configuration}`), {
        [`access.${entityName}`]: value,
      }),
  };
};
